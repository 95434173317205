import { BodyText } from "@/components/common/texts/body-text";
import { TitleText } from "@/components/common/texts/title-text";
import Image from "next/image";

interface Props {
  icon: string;
  title: string;
  description: string;
}

export const HouseRule = ({ icon, title, description }: Props) => {
  return (
    <div className="w-[80%] flex gap-x-[18px]">
      <Image
        width={500}
        height={500}
        src={icon}
        alt={title}
        className="w-6 h-6 object-cover"
      />
      <div className="flex flex-col gap-y-2.5">
        <TitleText className="mobile:hidden text-primaryB7 !leading-[20px] !text-[16px] !font-semibold">
          {title}
        </TitleText>
        <BodyText className="hidden mobile:block text-primaryB7 mobile:!leading-[16px] mobile:!text-[14px] mobile:!font-normal">
          {title}
        </BodyText>

        <BodyText className="text-neutralN7">{description}</BodyText>
      </div>
    </div>
  );
};
