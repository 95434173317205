"use client";

import { RoomType, SelectedRoom } from "@/types/room_type";
import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  rooms: RoomType[];
  setSelectedRooms: Dispatch<SetStateAction<SelectedRoom[]>>;
  selectedRooms: SelectedRoom[];
}

export const useLocalStorageRoomsData = ({
  rooms,
  selectedRooms,
  setSelectedRooms,
}: Props) => {
  useEffect(() => {
    if (selectedRooms.length > 0) return;
    const storedBookingData = localStorage.getItem("bookingData");
    if (storedBookingData) {
      try {
        const bookingData = JSON.parse(storedBookingData);

        const now = new Date();
        if (now.getTime() > bookingData.expiry) {
          localStorage.removeItem("bookingData");
          const fullVilla: SelectedRoom[] = rooms.map((r, index) => ({
            index,
            price: r.defaultPrice,
            roomId: r.roomId,
            guests: r.maximumOccupancy,
            propertyId: r.propertyId,
            isVilla: r.isVilla,
            roomName: r.name,
          }));
          setSelectedRooms(fullVilla);
          return;
        }

        const storedRoomIds: string[] = bookingData.bookedRooms;
        if (Array.isArray(storedRoomIds) && storedRoomIds.length > 0) {
          // Select only the rooms that are in storedRoomIds with correct indices
          const selected = rooms
            .filter((room) => storedRoomIds.includes(room.roomId))
            .map((room) => ({
              index: rooms.findIndex((r) => r.roomId === room.roomId), // Correct index assignment
              price: room.defaultPrice,
              roomId: room.roomId,
              guests: room.maximumOccupancy,
              propertyId: room.propertyId,
              isVilla: room.isVilla,
              roomName: room.name,
            }));

          if (!selected || selected.length === 0) {
            const fullVilla: SelectedRoom[] = rooms.map((r, index) => ({
              index,
              price: r.defaultPrice,
              roomId: r.roomId,
              guests: r.maximumOccupancy,
              propertyId: r.propertyId,
              isVilla: r.isVilla,
              roomName: r.name,
            }));
            setSelectedRooms(fullVilla);
          } else {
            setSelectedRooms(selected);
          }
        }
      } catch (error) {
        console.error("Error parsing bookingData from localStorage:", error);
      }
    } else {
      const fullVilla: SelectedRoom[] = rooms.map((r, index) => ({
        index,
        price: r.defaultPrice,
        roomId: r.roomId,
        guests: r.maximumOccupancy,
        propertyId: r.propertyId,
        isVilla: r.isVilla,
        roomName: r.name,
      }));
      setSelectedRooms(fullVilla);
    }
  }, [rooms, setSelectedRooms]);
};
