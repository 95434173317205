"use client";

import Image from "next/image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./images-carousel.css";
import * as gtag from "./../../../lib/gtag";
import { useRouter } from "next/navigation";

interface Props {
  images: string[];
  isAvailable?: boolean;
  isListingsPage?: boolean;
  isTag: boolean;
  isStickyImage?: boolean;
  propertyId: string;
  areaName?: string;
  city?: string;
}

export const ImagesCarousel = ({
  images,
  isAvailable,
  isListingsPage,
  isTag,
  isStickyImage,
  propertyId,
  areaName,
  city,
}: Props) => {
  const { push } = useRouter();
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    boxShadow: "none",
    autoplay: false,
    autoplaySpeed: 3000,
    afterChange: (_: number) => {
      const eventName = `${propertyId}_card_image_slided`;
      gtag.event({
        action: eventName,
        category: "engagement",
        label: "image slided",
        value: 1,
      });
    },
  };

  const handleRedirect = () => {
    if (isStickyImage)
      push(`/${city}/${areaName}/${propertyId}?showPhotos=true`);
  };

  return (
    <>
      {images.length > 1 ? (
        <Slider
          className={`w-full h-full listing-card_carousel ${
            isTag ? "tagged-listing" : ""
          }`}
          {...settings}
        >
          {images.map((item, index) => (
            <div
              onClick={handleRedirect}
              key={item + index}
              className="relative w-full h-full cursor-pointer"
            >
              <Image
                width={1000}
                height={1000}
                className={`w-full ${
                  isStickyImage
                    ? "h-[450px] mobile:h-[320px] rounded-[5px]"
                    : "h-[230px]"
                } object-cover ${
                  isListingsPage && !isAvailable && "grayscale"
                }`}
                src={item}
                alt="Property Image"
              />
            </div>
          ))}
        </Slider>
      ) : (
        <Image
          width={1000}
          height={1000}
          className={`w-full ${
            isStickyImage
              ? "h-[450px] mobile:h-[320px] rounded-[5px]"
              : "h-[230px]"
          } object-cover ${isListingsPage && !isAvailable && "grayscale"}`}
          src={images[0]}
          alt="Property Image"
        />
      )}
    </>
  );
};
