import { ReviewType } from "@/types/common";
import { TitleText } from "@/components/common/texts/title-text";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { Reviews } from "@/components/common/reviews";
import { Divider } from "./divider";

interface Props {
  allReviews: ReviewType[] | null;
  avgRating: number | null;
}

export const ReviewsSection = ({ allReviews, avgRating }: Props) => {
  if (!allReviews) return null;

  return (
    <>
      <section
        id="reviews-section"
        className="w-full mobile:px-5 flex flex-col gap-y-8 mobile:gap-y-5"
      >
        <TitleText className="text-primaryB7 lowercase mobile:!text-[24px] mobile:!leading-[28px]">
          <span className="flex items-center gap-x-2.5">
            <StarFilledIcon width={24} height={24} />{" "}
            <span>
              {avgRating && avgRating.toFixed(1)} stars | {allReviews.length}{" "}
              reviews
            </span>
          </span>
        </TitleText>
        <Reviews allReviews={allReviews} avgRating={avgRating} id={null} />
      </section>
      <Divider />
    </>
  );
};
