import { MobileSearchLongStayIcon } from "../../../../assets/mobile-search-long-stay-icon";
import { SearchPageLongStayIcon } from "../../../../assets/search-page-long-stay-icon";
import { BodyText } from "../texts/body-text";
import { TitleText } from "../texts/title-text";

export const LongStay = () => {
  return (
    <div
      className={`self-center text-center justify-center flex items-center gap-x-2`}
    >
      <div className="mobile:hidden">
        <SearchPageLongStayIcon />
      </div>
      <div className="hidden mobile:block">
        <MobileSearchLongStayIcon />
      </div>
      <TitleText className="mobile:hidden !normal-case text-success !leading-[26px] mobile:!leading-[18px]">
        LONGSTAY benefits available for your dates. Hurry!
      </TitleText>
      <BodyText className="text-success !normal-case hidden mobile:block">
        LONGSTAY benefits available for your dates. Hurry!
      </BodyText>
    </div>
  );
};
