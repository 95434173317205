import Link from "next/link";
import { FaWhatsapp } from "react-icons/fa";
import { TitleText } from "@/components/common/texts/title-text";
import { sendGTMEvent } from "@next/third-parties/google";
import * as gtag from "./../../../../../../lib/gtag";

export const WhatsappLink = ({ propertyName, id }: { propertyName: string, id: string }) => {

  const handleClick = () => {
    sendGTMEvent({
      event: "inbound_lead",
    });
    gtag.event({
      action: `${id}_contact_whatsapp`,
      category: 'inbound_lead',
      label: 'contact host through whatsapp clicked',
      value: 1,
    });
    console.log("inboud lead is gtm is sended");
  };

  return (
    <Link
      onClick={handleClick}
      target="_blank"
      href={`https://wa.me/918608600718?text=Hi%20Spacez,%20I've%20a%20question%20regarding%20${propertyName}`}
      className="w-full mobile:w-[90%] mobile:mx-auto flex justify-center items-center gap-x-2.5 self-stretch p-[10px] pl-[15px] rounded-[2.5px] bg-success shadow-[ -15px_13px_6px_0px_rgba(158,134,107,0.00),_-10px_9px_5px_0px_rgba(158,134,107,0.01),_-5px_5px_4px_0px_rgba(158,134,107,0.05),_-2px_2px_3px_0px_rgba(158,134,107,0.09),_-1px_1px_2px_0px_rgba(158,134,107,0.10)]"
    >
      <FaWhatsapp color="white" className="w-10 h-10 mobile:w-9 mobile:h-9" />
      <TitleText className="mobile:hidden text-white !text-[18px] !font-semibold !leading-[22px]">
        contact host and book your stay now
      </TitleText>
      <TitleText className="text-white !text-[14px] hidden mobile:block">
        contact host and book your stay now
      </TitleText>
    </Link>
  );
};
