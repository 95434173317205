"use client";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./images-carousel.css";
import { useEffect, useState } from "react";
import Link from "next/link";
import { PropertyDetailsFirebaseImpl } from "../../../../../data/property_data";
import { useRouter } from "next/navigation";
import { Skeleton } from "@/components/ui/skeleton";
import * as gtag from "./../../../../../lib/gtag"
interface Props {
  id: string;
  commonImages: string[];
}

export const MobileImagesCarousel = ({ id, commonImages }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [images, setImages] = useState<string[]>([]);
  const { push } = useRouter();

  useEffect(() => {
    (async () => {
      const propertyService = new PropertyDetailsFirebaseImpl();
      const propertyPhotos = await propertyService.getPropertyPhotos({
        propertyId: id,
      });

      if (!propertyPhotos?.photos || commonImages.length === 0)
        return push("/");
      const { photos } = propertyPhotos;

      // Extract the room order
      const roomOrder = photos.roomOrder || [];

      // Reorder the photos based on the room order
      const orderedPhotos: Record<string, string[]> = {};
      roomOrder.forEach((room) => {
        if (photos[room]) {
          orderedPhotos[room] = photos[room];
        }
      });
      const list: string[] = [];
      Object.entries(photos).forEach(([_, photoList]) => {
        photoList.forEach((photo) => {
          list.push(photo);
        });
      });

      setImages([...commonImages, ...list]);
    })();
  }, [id, push, commonImages]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: false,
    cssEase: "linear",
    boxShadow: "none",
    afterChange: (index: number) => {
      setCurrentSlide(index + 1)
      const eventName = `${id}_card_image_slided`;
      gtag.event({
        action: eventName,
        category: 'engagement',
        label: 'image slided',
        value: 1,
      });
    },
    adaptiveHeight: true, // Optional: can help with dynamic content
  };

  if (images.length === 0)
    return <Skeleton className="hidden mobile:block w-full h-[230px]" />;

  return (
    <div className="hidden mobile:block image-carousel relative overflow-hidden w-full h-[230px]">
      <Slider className="w-full h-full" {...settings}>
        {images.map((item, index) => (
          <Link
            key={item + index}
            href="?showPhotos=true"
            className="relative block w-full h-full"
          >
            <div
              className="w-full h-full"
              style={{
                backgroundImage: `url(${item})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover", // Use 'contain' to prevent cropping
              }}
            ></div>
          </Link>
        ))}
      </Slider>
      <div className="absolute flex items-center justify-center w-14 bottom-4 right-4 text-white text-sm bg-primaryB8 bg-opacity-50 px-3 py-2 rounded-[16px]">
        {currentSlide}/{images.length}
      </div>
    </div>
  );
};
