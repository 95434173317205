import { TitleText } from "@/components/common/texts/title-text";
import { ListingPageLongStayIcon } from "../../../../assets/listing-page-long-stay-icon";
import { LabelText } from "@/components/common/texts/label-text";
import { LongStayOfferOffersIcon } from "../../../../assets/long-stay-offer-offers-icon";
import { BodyText } from "@/components/common/texts/body-text";
import Link from "next/link";
import { handleCoupon, isCouponApplied } from "@/lib/coupons";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";
import { COUPON_TYPES } from "@/types/common";
import { HoliIcon } from "../../../../assets/holi-icon";
import { Lexend_Deca } from "next/font/google";
export const lexendDeca = Lexend_Deca({ subsets: ["latin"], weight: "700" });

interface Props {
  couponName?: string;
  description?: string;
  couponType?: string;
}

export const LongStayOffer = ({
  couponName,
  description,
  couponType,
}: Props) => {
  const { appliedCoupons, updateAppliedCoupons } = useUpdatePropertyCoupons(
    (state) => state
  );

  const handleClick = () => {
    if (!couponName || !description) return null;
    handleCoupon({
      couponName,
      appliedCoupons,
      updateAppliedCoupons,
      isProperty: true,
    });
  };

  const isApplied = isCouponApplied({
    coupons: appliedCoupons,
    couponName: couponName!,
  });

  return (
    <div className="w-full flex flex-col gap-y-2.5 pb-2.5 mobile:pb-0">
      {couponName ? (
        <div className="bg-white rounded-[2.5px] gap-[10px] pt-[10px] pb-[15px] px-5 border-t-[0.5px] border-r-[0.5px] border-b-[0.5px] border-l-8 border-success">
          <div className="flex flex-col gap-y-2.5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-[5px]">
                {couponType === COUPON_TYPES.LONG_STAY ? (
                  <ListingPageLongStayIcon />
                ) : (
                  <HoliIcon />
                )}
                {couponType === COUPON_TYPES.LONG_STAY ? (
                  <TitleText className="text-primaryB9 !text-[18px] !leading-[20px] mobile:!text-[16px] !mobile:leading-[18px] !uppercase">
                    {couponName}
                  </TitleText>
                ) : (
                  <h1
                    style={{
                      backgroundImage:
                        "linear-gradient(90deg, #EC5613 2.99%, #D32CB5 26.51%, #45BA93 50.03%, #FDA502 73.55%, #CF305C 97.07%)",
                    }}
                    className={`${lexendDeca.className} !text-[18px] !leading-[20px] bg-clip-text text-transparent`}
                  >
                    {couponName}
                  </h1>
                )}
              </div>
              <button onClick={handleClick}>
                <TitleText className="text-success !text-[16px] !leading-[18px] !uppercase">
                  {isApplied ? "REMOVE" : "APPLY"}
                </TitleText>
              </button>
            </div>
            <LabelText className="!font-light !text-[12px] !leading-[12px] text-neutralN8 !normal-case">
              {description}
            </LabelText>
          </div>
        </div>
      ) : null}
      <div className="mx-auto flex items-center gap-x-[5px]">
        <LongStayOfferOffersIcon />
        <Link href={`?offers=true`}>
          <BodyText className="text-neutralN8 !text-[14px] !leading-[16px] mobile:!text-[12px] mobile:!leading-[14px] !normal-case">
            {!couponName || !couponType
              ? "View all Offers >"
              : "View other Offers >"}
          </BodyText>
        </Link>
      </div>
    </div>
  );
};
