import { BodyText } from "@/components/common/texts/body-text";
import { LabelText } from "@/components/common/texts/label-text";
import { TitleText } from "@/components/common/texts/title-text";
import Image from "next/image";

interface Props {
  title: string;
  description: string;
  icon: string;
}

export const Highlight = ({ title, description, icon }: Props) => {
  return (
    <div className="flex items-start gap-x-4">
      <Image
        width={1000}
        height={1000}
        src={icon}
        alt="Highlight Icon"
        className="w-6 h-6 object-cover"
      />

      <div className="flex flex-col gap-y-2.5 w-[90%]">
        <TitleText className="mobile:hidden !font-semibold !text-[16px] !leading-[20px] text-primaryB7">
          {title}
        </TitleText>

        <BodyText className="hidden mobile:block text-primaryB7 mobile:!font-normal mobile:!text-[14px] mobile:!leading-[16px]">
          {title}
        </BodyText>

        <BodyText className="text-neutralN7 mobile:hidden">
          {description}
        </BodyText>

        <LabelText className="hidden mobile:block text-neutralN8">
          {description}
        </LabelText>
      </div>
    </div>
  );
};
