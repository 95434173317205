export const ListingPageLongStayIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3866_10545"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3866_10545)">
        <path
          d="M1.66699 16.6663V8.33301L6.66699 3.33301L11.667 8.33301V16.6663H1.66699ZM3.33366 14.9997H5.83366V12.4997H7.50033V14.9997H10.0003V9.02051L6.66699 5.68717L3.33366 9.02051V14.9997ZM5.83366 10.833V9.16634H7.50033V10.833H5.83366ZM13.3337 16.6663V7.64551L9.02116 3.33301H11.3753L15.0003 6.95801V16.6663H13.3337ZM16.667 16.6663V6.27051L13.7295 3.33301H16.0837L18.3337 5.58301V16.6663H16.667Z"
          fill="#33312F"
        />
      </g>
    </svg>
  );
};
