"use client";

import React, { useState } from "react";
import "./faq.css";
import { FaChevronDown } from "react-icons/fa";
import { TitleText } from "@/components/common/texts/title-text";
import { LabelText } from "@/components/common/texts/label-text";

interface Props {
  item: {
    question: string;
    answer: string;
  };
}

export const FAQ = ({ item }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`faq-list-item ${
        isExpanded &&
        "faq-list-item-height  border-neutralN6 border-b-[0.5px] pt-1.4 pb-5 "
      }`}
    >
      <div
        onClick={() => setIsExpanded((prev) => !prev)}
        className="border-b-[0.5px] pt-1.4 pb-5 border-neutralN6 w-auto mobile:w-full items-center justify-between mobile:justify-between mobile:gap-x-0 flex cursor-pointer overflow-y-hidden"
      >
        <TitleText className="w-[80%] text-neutralN7 !font-semibold !text-[16px]">
          {item.question}
        </TitleText>
        <FaChevronDown
          color="#9b938a"
          className={`${
            isExpanded ? "text-text" : "text-grey"
          } faq-chevron-down-icon ${
            isExpanded && "faq-chevron-down-icon-rotate"
          }`}
        />
      </div>
      {isExpanded && (
        <LabelText className="py-5 !text-[12px] !font-normal text-neutralN7 w-[60%] block">
          {item.answer}
        </LabelText>
      )}
    </div>
  );
};
