export const SearchPageEarlyBirdIcon = () => {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 23.75C17.2083 23.75 16.3229 23.3854 15.5938 22.6562C14.8646 21.9271 14.5 21.0417 14.5 20C14.5 18.9583 14.8646 18.0729 15.5938 17.3438C16.3229 16.6146 17.2083 16.25 18.25 16.25C19.2917 16.25 20.1771 16.6146 20.9062 17.3438C21.6354 18.0729 22 18.9583 22 20C22 21.0417 21.6354 21.9271 20.9062 22.6562C20.1771 23.3854 19.2917 23.75 18.25 23.75ZM17.3125 15V12.5H19.1875V15H17.3125ZM17.3125 27.5V25H19.1875V27.5H17.3125ZM22.4688 17.0938L21.125 15.7812L22.9062 14L24.2188 15.3438L22.4688 17.0938ZM13.5938 25.9688L12.2812 24.6562L14.0625 22.875L15.375 24.1875L13.5938 25.9688ZM23.25 20.9375V19.0625H25.75V20.9375H23.25ZM10.75 20.9375V19.0625H13.25V20.9375H10.75ZM22.9062 25.9688L21.1562 24.1875L22.4688 22.875L24.25 24.625L22.9062 25.9688ZM14.0312 17.125L12.2812 15.3438L13.5938 14.0312L15.375 15.7812L14.0312 17.125ZM3.25 25C2.5625 25 1.97396 24.7552 1.48438 24.2656C0.994792 23.776 0.75 23.1875 0.75 22.5V5C0.75 4.3125 0.994792 3.72396 1.48438 3.23438C1.97396 2.74479 2.5625 2.5 3.25 2.5H4.5V0H7V2.5H17V0H19.5V2.5H20.75C21.4375 2.5 22.026 2.74479 22.5156 3.23438C23.0052 3.72396 23.25 4.3125 23.25 5V10H3.25V22.5H8.25V25H3.25ZM3.25 7.5H20.75V5H3.25V7.5Z"
        fill="#E09132"
      />
    </svg>
  );
};
