export const DesktopOffersModalCloseIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8733 1.4865C14.7082 1.4904 14.5514 1.55944 14.4371 1.67856L7.99989 8.11573L1.56272 1.67856C1.50429 1.61861 1.43443 1.57101 1.35726 1.53858C1.2801 1.50615 1.1972 1.48955 1.1135 1.48976C0.989353 1.49012 0.868127 1.52743 0.765273 1.59695C0.662419 1.66647 0.582595 1.76504 0.535978 1.8801C0.48936 1.99516 0.478061 2.1215 0.503519 2.243C0.528978 2.36451 0.590042 2.47568 0.678927 2.56235L7.1161 8.99952L0.687065 15.4286C0.627083 15.4862 0.579194 15.5551 0.546205 15.6315C0.513215 15.7078 0.495787 15.7899 0.494942 15.8731C0.494096 15.9562 0.50985 16.0387 0.541281 16.1157C0.572711 16.1927 0.619187 16.2626 0.677986 16.3214C0.736785 16.3802 0.806724 16.4267 0.88371 16.4581C0.960695 16.4896 1.04318 16.5053 1.12633 16.5045C1.20948 16.5036 1.29162 16.4862 1.36795 16.4532C1.44428 16.4202 1.51326 16.3723 1.57085 16.3123L7.99989 9.88331L14.4306 16.3156C14.4887 16.3738 14.5577 16.4199 14.6337 16.4514C14.7096 16.4828 14.7911 16.499 14.8733 16.499C14.9555 16.499 15.0369 16.4828 15.1129 16.4514C15.1889 16.4199 15.2579 16.3738 15.316 16.3157C15.3742 16.2575 15.4203 16.1885 15.4517 16.1125C15.4832 16.0366 15.4994 15.9551 15.4994 15.8729C15.4994 15.7907 15.4832 15.7093 15.4517 15.6333C15.4202 15.5573 15.3741 15.4883 15.316 15.4302L8.88368 8.99952L15.3209 2.56235C15.4117 2.47512 15.4741 2.36246 15.4999 2.23914C15.5256 2.11582 15.5135 1.9876 15.4651 1.87129C15.4167 1.75498 15.3343 1.656 15.2287 1.58734C15.1231 1.51868 14.9992 1.48353 14.8733 1.4865Z"
        fill="#9B938A"
        stroke="#9B938A"
      />
    </svg>
  );
};
