export enum BookingStatus {
  PAYMENT_DONE = "PAYMENT_DONE",
  CANCELLED = "CANCELLED",
  SUBMITTED = "SUBMITTED",
  CONFIRMED = "CONFIRMED",
}

export type BookingDataType = {
  bookingId: string;
  propertyId: string;
  purpose: string;
  bookedRooms: string[];
  email: string;
  checkInDate: string;
  checkOutDate: string;
  name: string;
  number: string;
  noPeople: number;
  status: "PAYMENT_DONE" | "CANCELLED" | "SUBMITTED";
};

export type UserBookingDataType = BookingDataType & {
  id: string;
  propertyImage: string;
  propertyName: string;
  payment: number;
  gstPayment: number;
  commonImages: string[];
  areaName: string;
  city: string;
  countryCode: string;
};
