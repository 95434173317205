import { useEffect, useMemo, useState } from "react";
import { HouseRule } from "./house-rule";
import { PropertyDetailsFirebaseImpl } from "../../../../../../../data/property_data";
import { TitleText } from "@/components/common/texts/title-text";

export const HouseRules = () => {
  const [houseRules, setHouseRules] = useState<
    { title: string; icon: string; description: string }[]
  >([]);

  const propertyService = useMemo(() => new PropertyDetailsFirebaseImpl(), []);

  useEffect(() => {
    (async () => {
      const data = await propertyService.getHouseRules();
      if (data) {
        setHouseRules(data);
      }
    })();
  }, [propertyService]);

  return (
    <section className="w-full flex flex-col gap-y-8 mobile:gap-y-5 mobile:px-5">
      <TitleText className="text-primaryB7 lowercase mobile:!text-[24px] mobile:!leading-[28px]">
        House rules
      </TitleText>
      <div className="w-full grid grid-cols-2 justify-between gap-y-5">
        {houseRules.map(({ title, description, icon }, index) => (
          <HouseRule
            key={title + index}
            title={title}
            description={description}
            icon={icon}
          />
        ))}
      </div>
    </section>
  );
};
