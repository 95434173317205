import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCkY8K86vgUPGhXxLi2EChYCIlKvE-lWxE",
  authDomain: "spacez-all.firebaseapp.com",
  projectId: "spacez-all",
  storageBucket: "spacez-all.appspot.com",
  messagingSenderId: "988598930373",
  appId: "1:988598930373:web:503d7c4c9abcb6f9fd99ac",
  measurementId: "G-EM0CSRF6R2",
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
