export const MobileSearchEarlyBirdIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3966_433"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3966_433)">
        <path
          d="M14.1667 17.5003C13.4722 17.5003 12.8819 17.2573 12.3958 16.7712C11.9097 16.285 11.6667 15.6948 11.6667 15.0003C11.6667 14.3059 11.9097 13.7156 12.3958 13.2295C12.8819 12.7434 13.4722 12.5003 14.1667 12.5003C14.8611 12.5003 15.4514 12.7434 15.9375 13.2295C16.4236 13.7156 16.6667 14.3059 16.6667 15.0003C16.6667 15.6948 16.4236 16.285 15.9375 16.7712C15.4514 17.2573 14.8611 17.5003 14.1667 17.5003ZM13.5417 11.667V10.0003H14.7917V11.667H13.5417ZM13.5417 20.0003V18.3337H14.7917V20.0003H13.5417ZM16.9792 13.0628L16.0833 12.1878L17.2708 11.0003L18.1458 11.8962L16.9792 13.0628ZM11.0625 18.9795L10.1875 18.1045L11.375 16.917L12.25 17.792L11.0625 18.9795ZM17.5 15.6253V14.3753H19.1667V15.6253H17.5ZM9.16667 15.6253V14.3753H10.8333V15.6253H9.16667ZM17.2708 18.9795L16.1042 17.792L16.9792 16.917L18.1667 18.0837L17.2708 18.9795ZM11.3542 13.0837L10.1875 11.8962L11.0625 11.0212L12.25 12.1878L11.3542 13.0837ZM4.16667 18.3337C3.70833 18.3337 3.31597 18.1705 2.98958 17.8441C2.66319 17.5177 2.5 17.1253 2.5 16.667V5.00033C2.5 4.54199 2.66319 4.14963 2.98958 3.82324C3.31597 3.49685 3.70833 3.33366 4.16667 3.33366H5V1.66699H6.66667V3.33366H13.3333V1.66699H15V3.33366H15.8333C16.2917 3.33366 16.684 3.49685 17.0104 3.82324C17.3368 4.14963 17.5 4.54199 17.5 5.00033V8.33366H4.16667V16.667H7.5V18.3337H4.16667ZM4.16667 6.66699H15.8333V5.00033H4.16667V6.66699Z"
          fill="#C27315"
        />
      </g>
    </svg>
  );
};
