"use client";

import PhotoModalOverlay from "@/components/photo-modal";
import { useIsMobile } from "@/hooks/use-is-mobile";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useState, useCallback, useEffect, useMemo } from "react";
import { DropdownIcon } from "../../../../../assets/dropdown-icon";

interface Photo {
  photo: string;
  section: string;
}

interface SinglePhotoModalProps {
  image: string;
  photos: Record<string, string[]>;
  category: string;
}

export const SinglePhotoModal = ({
  image,
  photos,
  category,
}: SinglePhotoModalProps) => {
  // Combine all photos into a flat list with section information
  const flatPhotos: Photo[] = useMemo(() => {
    const list: Photo[] = [];
    Object.entries(photos).forEach(([section, photoList]) => {
      photoList.forEach((photo) => {
        list.push({ photo, section });
      });
    });
    return list;
  }, [photos]);

  // Find the index of the current image in the flat list
  const initialIndex = useMemo(() => {
    return flatPhotos.findIndex((p) => p.photo === image);
  }, [flatPhotos, image]);

  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [distance, setDistance] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);
  const isMobile = useIsMobile({});

  const router = useRouter();

  const currentPhoto = flatPhotos[currentIndex];

  const onClose = useCallback(() => {
    router.back();
  }, [router]);

  const navigateToPhoto = useCallback(
    (newIndex: number) => {
      const totalPhotos = flatPhotos.length;
      // Ensure the new index wraps around
      const wrappedIndex = (newIndex + totalPhotos) % totalPhotos;
      const photo = flatPhotos[wrappedIndex];

      if (!photo) return; // Safety check

      if (isMobile) {
        setTimeout(() => {
          setCurrentIndex(wrappedIndex);
        }, 350);
      } else {
        setCurrentIndex(wrappedIndex);
      }

      // Update URL parameters
      const currentParams = new URLSearchParams(window.location.search);
      currentParams.set("singlePhoto", photo.photo);
      currentParams.set("category", photo.section);
      const newQueryString = currentParams.toString();
      const newUrl = `${window.location.pathname}?${newQueryString}`;
      router.replace(newUrl, { scroll: false });
    },
    [flatPhotos, isMobile, router]
  );

  const handleNext = useCallback(() => {
    navigateToPhoto(currentIndex + 1);
  }, [currentIndex, navigateToPhoto]);

  const handlePrev = useCallback(() => {
    navigateToPhoto(currentIndex - 1);
  }, [currentIndex, navigateToPhoto]);

  const threshold = 50;

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setDistance(0);
    setIsAnimating(false);
  }, []);

  const handleTouchMove = useCallback(
    (e: React.TouchEvent) => {
      if (touchStartX !== null) {
        const currentX = e.touches[0].clientX;
        const delta = currentX - touchStartX;
        setDistance(delta);
      }
    },
    [touchStartX]
  );

  const handleTouchEnd = useCallback(() => {
    if (distance > threshold) {
      setIsAnimating(true);
      setDistance(window.innerWidth);
      handlePrev();
      setTimeout(() => {
        setIsAnimating(false);
      }, 400);
    } else if (distance < -threshold) {
      setIsAnimating(true);
      setDistance(-window.innerWidth);
      handleNext();
      setTimeout(() => {
        setIsAnimating(false);
      }, 400);
    } else {
      setIsAnimating(false);
      setDistance(0);
    }

    setTouchStartX(null);
  }, [distance, handleNext, handlePrev]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        handleNext();
      } else if (event.key === "ArrowLeft") {
        handlePrev();
      } else if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNext, handlePrev, onClose]);

  return (
    <PhotoModalOverlay
      className="!z-[100000] !overflow-hidden !p-[0px] mobile:!top-[50%] mobile:!left-1/2 mobile:!-translate-y-1/2 mobile:!-translate-x-1/2 mobile:!w-full mobile:!h-full"
      onClose={onClose}
      numberOfPhotos={flatPhotos.length}
      currentPhoto={currentIndex + 1}
      photoCategory={currentPhoto.section}
    >
      <div
        className="relative w-full h-full"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={`relative w-full h-full ${
            isAnimating ? "transition-transform duration-300 ease-out" : ""
          }`}
          style={{
            transform: isAnimating ? `translateX(${distance}px)` : "none",
            willChange: "transform",
            backfaceVisibility: "hidden",
          }}
        >
          <button
            style={{ transition: "all 100ms" }}
            className="absolute top-1/2 left-4 mobile:hidden z-[1000000] cursor-pointer rotate-90"
            onClick={handlePrev}
            aria-label="Previous Photo"
          >
            <DropdownIcon width="100" height="100" />
          </button>
          <Image
            src={currentPhoto.photo}
            alt={`Photo ${currentIndex + 1} in ${currentPhoto.section}`}
            layout="fill"
            objectFit="contain"
            className="absolute object-contain cursor-pointer"
            priority
            fetchPriority="high"
          />
          <button
            style={{ transition: "all 100ms" }}
            className="absolute top-1/2 right-4 mobile:hidden z-[1000000] cursor-pointer -rotate-90"
            onClick={handleNext}
            aria-label="Next Photo"
          >
            <DropdownIcon width="100" height="100" />
          </button>
        </div>
      </div>
    </PhotoModalOverlay>
  );
};
