import { useEffect, useMemo, useState } from "react";
import { Highlight } from "./highlight";
import { HighlightType } from "@/types/common";
import { PropertyDetailsFirebaseImpl } from "../../../../../../../data/property_data";

export const Highlights = ({ propertyId }: { propertyId: string }) => {
  const [highlights, setHighlights] = useState<HighlightType[]>([]);

  const propertyService = useMemo(() => new PropertyDetailsFirebaseImpl(), []);

  useEffect(() => {
    (async () => {
      const data = await propertyService.getPropertyHighlights({ propertyId });
      if (!data) {
        setHighlights([]);
      } else {
        setHighlights(data);
      }
    })();
  }, [propertyService, propertyId]);

  return (
    <div className="w-full mx-auto mobile:px-5">
      <div className="w-full flex flex-col gap-y-7">
        {highlights.map(({ icon, title, description }) => (
          <Highlight
            key={icon}
            icon={icon}
            title={title}
            description={description}
          />
        ))}
      </div>
    </div>
  );
};
