import { MobileSearchEarlyBirdIcon } from "../../../../assets/mobile-search-early-bird-icon";
import { SearchPageEarlyBirdIcon } from "../../../../assets/search-page-early-bird-icon";
import { BodyText } from "../texts/body-text";
import { LabelText } from "../texts/label-text";
import { TitleText } from "../texts/title-text";
import { useEffect, useState } from "react";

const date = new Date();
date.setDate(date.getDate() + 5);

export const EarlyBird = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = date.getTime() - new Date().getTime();
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          mins: Math.floor((difference / (1000 * 60)) % 60),
          secs: Math.floor((difference / 1000) % 60),
        });
      }
    };

    // Initial calculation
    calculateTimeLeft();

    // Set up interval for countdown
    const timer = setInterval(calculateTimeLeft, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "5px",
        background: "#FFF7E9",
        padding: "20px 48px 20px 28px", // equivalent to pr-12 pl-7 py-5
      }}
      className="relative w-full mobile:!px-4 mobile:!py-2"
    >
      <div
        style={{
          content: "",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: "-2px", // border width
          borderRadius: "7px", // slightly larger to account for the negative margin
          background:
            "linear-gradient(85.04deg, #E09132 0%, #EDECEA 50%, #E09132 100%)",
        }}
        className="absolute"
      />
      <div className="w-full flex items-center justify-between mobile:flex-col mobile:justify-center">
        <div className="flex items-center gap-x-[5px]">
          <div className="mobile:hidden">
            <SearchPageEarlyBirdIcon />
          </div>
          <div className="hidden mobile:block">
            <MobileSearchEarlyBirdIcon />
          </div>
          <TitleText className="mobile:hidden text-[#E09132] !text-[18px] !leading-[20px] !normal-case">
            EARLYBIRD is Live! Book Now to get flat 10% off
          </TitleText>
          <BodyText className="hidden mobile:block text-[#E09132] !normal-case">
            EARLYBIRD is Live! Book Now to get flat 10% off
          </BodyText>
        </div>

        <div className="flex items-center gap-x-[5px]">
          <TimeBox value={String(timeLeft.days).padStart(2, "0")} type="days" />
          <TitleText className="text-primaryB7 !leading-[26px] mobile:!leading-[18px]">
            :
          </TitleText>
          <TimeBox
            value={String(timeLeft.hours).padStart(2, "0")}
            type="hours"
          />
          <TitleText className="text-primaryB7 !leading-[26px] mobile:!leading-[18px]">
            :
          </TitleText>
          <TimeBox value={String(timeLeft.mins).padStart(2, "0")} type="mins" />
          <TitleText className="text-primaryB7 !leading-[26px] mobile:!leading-[18px]">
            :
          </TitleText>
          <TimeBox value={String(timeLeft.secs).padStart(2, "0")} type="secs" />
        </div>
      </div>
    </div>
  );
};

export const TimeBox = ({ value, type }: { value: string; type: string }) => {
  return (
    <div className="w-12 flex flex-col gap-y-1 items-center justify-center border border-neutralN5 py-3 px-2 bg-white/50 rounded-[2.5px]">
      <TitleText className="text-primaryB7 !leading-[26px] mobile:!leading-[18px]">
        {value}
      </TitleText>
      <LabelText className="!font-light text-primaryB7 !leading-[16px] mobile:!leading-[12px]">
        {type}
      </LabelText>
    </div>
  );
};
