import { Photos } from "../../photos";

interface Props {
  images: string[];
  propertyName: string;
}

export const MobilePhotos = ({ images, propertyName }: Props) => {
  return (
    <div className="hidden mobile:block px-5">
      <Photos
        image1={images[0]}
        image2={images[1]}
        image3={images[2]}
        propertyName={propertyName}
      />
    </div>
  );
};
