import { db } from "../lib/connect-firebase"; // Make sure the path is correct
import { collection, getDocs } from "firebase/firestore";
import { AmenityType } from "../src/types/properties_type";

export class GlobalDataService {
  private _globalData = collection(db, "globalData");

  async getAmenities() {
    try {
      const querySnapshot = await getDocs(this._globalData);

      // Assuming that each document in the collection contains an array of amenities
      const amenities: AmenityType[] = querySnapshot.docs.map(
        (doc) => doc.data().amenities as AmenityType
      );

      return amenities;
    } catch (error) {
      console.log('No document found in the "roomTypes" collection.');
      return [];
    }
  }
}
