import { useEffect, useState } from "react";
import { MobileSearchRushDealIcon } from "../../../../assets/mobile-search-rushdeal-icon";
import { BodyText } from "../texts/body-text";
import { TitleText } from "../texts/title-text";
import { TimeBox } from "./early-bird";
import { SearchPageRushDealIcon } from "../../../../assets/search-page-rushdeal-icon";

export const RushDeal = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      // Get current time
      const now = new Date();
      
      // Create target date based on current time
      const targetDate = new Date(now);
      
      // Get current hour
      const currentHour = now.getHours();
      
      // Set target time based on current time
      if (currentHour >= 0 && currentHour < 6) {
        // After 12am but before 6am - countdown to 6am
        targetDate.setHours(6, 0, 0, 0);
      } else if (currentHour >= 6 && currentHour < 18) {
        // After 6am but before 6pm - countdown to 6pm
        targetDate.setHours(18, 0, 0, 0);
      } else {
        // After 6pm - countdown to 12am the next day
        targetDate.setDate(targetDate.getDate() + 1);
        targetDate.setHours(0, 0, 0, 0);
      }
      
      // Calculate the time difference
      const difference = targetDate.getTime() - now.getTime();
      
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          mins: Math.floor((difference / (1000 * 60)) % 60),
          secs: Math.floor((difference / 1000) % 60),
        });
      }
    };

    // Initial calculation
    calculateTimeLeft();

    // Set up interval for countdown
    const timer = setInterval(calculateTimeLeft, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "5px",
        background: "#FFF4EE",
        padding: "20px 48px 20px 28px", // equivalent to pr-12 pl-7 py-5
      }}
      className="relative w-full mobile:!px-4 mobile:!py-2"
    >
      <div
        style={{
          content: "",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          margin: "-2px", // border width
          borderRadius: "7px", // slightly larger to account for the negative margin
          background:
            "linear-gradient(274.96deg, #9E0F0F 0%, #D5B7B7 50%, #9E0F0F 100%)",
        }}
        className="absolute"
      />
      <div className="w-full flex items-center justify-between mobile:flex-col mobile:justify-center">
        <div className="flex items-center gap-x-[5px]">
          <div className="mobile:hidden">
            <SearchPageRushDealIcon />
          </div>
          <div className="hidden mobile:block">
            <MobileSearchRushDealIcon />
          </div>
          <TitleText className="mobile:hidden text-[#DF3838] !text-[18px] !leading-[20px] !normal-case">
            RUSHDEAL is Live! Book Now and Save Big
          </TitleText>
          <BodyText className="hidden mobile:block text-[#DF3838] !normal-case">
            RUSHDEAL is Live! Book Now and Save Big
          </BodyText>
        </div>
        <div className="flex items-center gap-x-[5px]">
          <TimeBox
            value={String(timeLeft.hours).padStart(2, "0")}
            type="hours"
          />
          <TitleText className="text-primaryB7 !leading-[26px] mobile:!leading-[18px]">
            :
          </TitleText>
          <TimeBox value={String(timeLeft.mins).padStart(2, "0")} type="mins" />
          <TitleText className="text-primaryB7 !leading-[26px] mobile:!leading-[18px]">
            :
          </TitleText>
          <TimeBox value={String(timeLeft.secs).padStart(2, "0")} type="secs" />
        </div>
      </div>
    </div>
  );
};