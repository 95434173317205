import { HeroPropertyType } from "../types/properties_type";
import { TitleText } from "./common/texts/title-text";
import { BodyText } from "./common/texts/body-text";
import { TagDataType } from "@/types/common";
import { ListingTicker } from "./common/listing-ticker";
import { ImagesCarousel } from "./images-carousel";
import Link from "next/link";
import { StarFilledIcon } from "@radix-ui/react-icons";
import { formatIndianCurrency, getDiscountPercentage } from "../../lib/utility";
import { HeadlineText } from "./common/texts/headline-text";
import { Skeleton } from "./ui/skeleton";
import { LabelText } from "./common/texts/label-text";
import { DiscountIcon } from "../../assets/discount-icon";

interface Props {
  property: HeroPropertyType;
  index?: number;
  listingsPage?: boolean;
  isAvailable?: boolean;
  isPadding?: boolean;
  isRelatedProperties?: boolean;
  tag: TagDataType | null;
  avgRating: number | null;
}

export const ListingCard = ({
  property,
  index,
  listingsPage,
  isAvailable,
  isPadding,
  isRelatedProperties,
  tag,
  avgRating,
}: Props) => {
  const {
    propertyId,
    images,
    name,
    location,
    noOfBathrooms,
    noOfBedrooms,
    city,
    area,
    defaultOccupancy,
    price: defaultPrice,
  } = property;

  const borderColor = tag ? `${tag.tagId}PropertyCard` : "#E0AB8F";

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}..` : text;
  };

  const discountPercentage = isAvailable
    ? property.total_price && property.discounted_price
      ? property.total_price > +defaultPrice
        ? property.discount_percentage
        : getDiscountPercentage({
            originalPrice: +defaultPrice,
            salePrice: property.discounted_price,
          })
      : 0
    : 0;

  return (
    <Link
      href={
        listingsPage && !isAvailable
          ? `/${city}/${area}/${propertyId}?booked=true`
          : `/${city}/${area}/${propertyId}`
      }
      target="_blank"
      key={propertyId}
      className={`listing-card ${
        index === 0 && isPadding && "mobile:ml-[30px] ml-[100px]"
      } ${listingsPage && "mobile:!w-full !w-auto"} ${
        isRelatedProperties && "!w-[350px] mobile:!w-[310px]"
      } overflow-hidden h-auto rounded-[5px] border-[2px] ${borderColor} ${
        listingsPage && !isAvailable && "soldOutPropertyCard"
      } border-primaryB7 relative`}
    >
      <div className="relative w-full h-[230px]">
        {listingsPage && !isAvailable && (
          <ListingTicker
            tagId="soldOut"
            text="sold out"
            iconImage="https://res.cloudinary.com/dz3tveb47/image/upload/v1731587697/sentiment_sad_qvvfue.png"
          />
        )}

        {(isAvailable || !isAvailable) && tag && (
          <ListingTicker
            tagId={!isAvailable && listingsPage ? "soldOut" : tag.tagId}
            text={!isAvailable && listingsPage ? "sold out" : tag.tagName}
            iconImage={
              !isAvailable && listingsPage
                ? "https://res.cloudinary.com/dz3tveb47/image/upload/v1731587697/sentiment_sad_qvvfue.png"
                : tag.tagIcon
            }
          />
        )}
        <ImagesCarousel
          propertyId={propertyId}
          isTag={tag || (listingsPage && !isAvailable) ? true : false}
          images={images}
          isAvailable={isAvailable}
          isListingsPage={listingsPage}
        />
      </div>

      <div className={`flex flex-col gap-y-[15px] h-auto w-full p-[15px] pr-2`}>
        <div className="flex flex-col items-start relative">
          <HeadlineText
            className={`text-primaryB7 !font-extrabold !capitalize !text-[26px] !leading-[28px] -tracking-[0.06] ${
              !isAvailable && listingsPage && "!text-neutralN7"
            } mobile:!text-[24px] mobile:!leading-[26px]`}
          >
            {truncateText(name, 19)}
          </HeadlineText>
          <BodyText className="opacity-50 !text-[12px] !leading-[14px] !font-normal text-neutralN8">
            {location}
          </BodyText>
          <div className="mt-2">
            <BodyText className="!text-[12px] !leading-[14px] !font-normal text-neutralN8">
              {defaultOccupancy} guests • {noOfBedrooms} Rooms • {noOfBathrooms}{" "}
              Baths
            </BodyText>
          </div>
          <div className="absolute top-1.5 right-1">
            {avgRating ? (
              <div className="flex items-center gap-x-1">
                <StarFilledIcon color="#51311F" />
                <TitleText className="text-primaryB7 !font-medium !text-[16px] !leading-[18px]">
                  {avgRating}
                </TitleText>
              </div>
            ) : (
              <div className="flex items-center gap-x-1">
                <StarFilledIcon color="#51311F" />
                <TitleText className="text-primaryB7 !font-medium !text-[16px] !leading-[18px]">
                  new
                </TitleText>
              </div>
            )}
          </div>
        </div>
        {!property?.discounted_price || !property?.total_price ? (
          <PriceSkeleton />
        ) : (
          <div className="w-full flex items-end justify-between">
            <div className="flex gap-y-1 flex-col">
              {property.discounted_price < property.total_price ? (
                <LabelText className="text-error !font-light !text-[12px] !leading-[12px] line-through">
                  ₹{" "}
                  {formatIndianCurrency(
                    property.total_price > +defaultPrice
                      ? property.total_price
                      : +defaultPrice
                  )}
                </LabelText>
              ) : null}
              <TitleText className="text-primaryB9 !text-[18px] lowercase inline !text-left !leading-[20px]">
                ₹ {formatIndianCurrency(+property?.discounted_price)}
                <BodyText className="!text-[12px] !font-normal text-neutralN8 inline">
                  /night
                </BodyText>
              </TitleText>
            </div>
            {discountPercentage ? (
              <Discount discountPercentage={discountPercentage} />
            ) : null}
          </div>
        )}
      </div>
    </Link>
  );
};

const PriceSkeleton = () => {
  return <Skeleton className="w-full h-[30px]" />;
};

const Discount = ({ discountPercentage }: { discountPercentage: number }) => {
  return (
    <div
      style={{
        background:
          "linear-gradient(182.95deg, #2E8017 2.43%, #3EA920 121.32%)",
      }}
      className="flex items-center gap-x-[5px] py-[3px] px-[7px] rounded-[2.5px]"
    >
      <DiscountIcon />
      <BodyText className="text-[#FCF3E3] !text-[14px] !leading-[16px]">{`${discountPercentage}% Off`}</BodyText>
    </div>
  );
};
