import { EarlyBird } from "@/components/common/search-page-coupons/early-bird";
import { LongStay } from "@/components/common/search-page-coupons/long-stay";
import { RushDeal } from "@/components/common/search-page-coupons/rush-deal";
import { isSameDay } from "@/lib/search-data";
import { useUpdateSearchPageCoupons } from "@/store/update-search-page-coupons";
import { COUPON_TYPES } from "@/types/common";

interface Props {
  checkInDate: Date;
}

export const Coupons = ({ checkInDate }: Props) => {
  const { coupons } = useUpdateSearchPageCoupons((state) => state);

  if (!coupons || coupons.length === 0) return null;

  return (
    <div className="w-full flex flex-col gap-y-5 mobile:gap-y-2 mobile:mt-1 mobile:px-5">
      {checkInDate ? (
        coupons?.includes(COUPON_TYPES.EARLY_BIRD) ? (
          <EarlyBird />
        ) : coupons?.includes(COUPON_TYPES.LAST_MINUTE) ? (
          isSameDay(new Date(checkInDate)) ? (
            <RushDeal />
          ) : null
        ) : null
      ) : null}
      {coupons?.includes(COUPON_TYPES.LONG_STAY) ? (
        <LongStay />
      ) : null}
    </div>
  );
};
