import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"; // Firestore Client SDK methods
import { Bookings } from "../lib/classes/bookings-class"; // Adjust the path to your model
import { db } from "../lib/connect-firebase"; // Ensure this points to Firestore Client SDK instance
import { BookingDataType, BookingStatus } from "../src/types/bookings_type";

class BookingDetailsFirebaseImpl {
  // Using Firestore Client SDK collection reference
  private _bookings = collection(db, "bookings"); // No change to this line

  // Save booking data to Firestore
  async saveBookingData(bookingData: Bookings) {
    console.log(`User info added is ${JSON.stringify(bookingData.toJson())}`);

    try {
      // Get document reference with the provided booking ID
      const docRef = doc(this._bookings, bookingData.id); // Firestore Client SDK method
      await setDoc(docRef, bookingData.toJson()); // Use setDoc() from Firestore Client SDK
      console.log(`User information added to Firestore with ID: ${docRef.id}`);
      return { success: true };
    } catch (error) {
      console.error(`Error adding user information: ${error}`);
    }
  }

  // Update the paymentId
  async updatePaymentId({
    paymentId,
    id,
  }: {
    paymentId: string;
    id: string;
  }): Promise<void> {
    try {
      // Get document reference and update the 'paymentId' field in the document
      const docRef = doc(this._bookings, id); // Firestore Client SDK method
      await updateDoc(docRef, { paymentId: paymentId }); // Use updateDoc() from Firestore Client SDK
      console.log(`User paymentId updated to: ${paymentId}`);
    } catch (error) {
      console.error(`Error updating user status: ${error}`);
    }
  }
  // Update the booking status
  async updateBookingStatus({
    newStatus,
    id,
  }: {
    newStatus: string;
    id: string;
  }): Promise<void> {
    try {
      // Get document reference and update the 'status' field in the document
      const docRef = doc(this._bookings, id); // Firestore Client SDK method
      await updateDoc(docRef, { status: newStatus }); // Use updateDoc() from Firestore Client SDK
      console.log(`User sta2tus updated to: ${newStatus}`);
    } catch (error) {
      console.error(`Error updating user status: ${error}`);
    }
  }

  async fetchBookingsBasedOnUser({
    phoneNumber,
  }: {
    phoneNumber: string;
  }): Promise<BookingDataType[] | null> {
    try {
      // Create a query to fetch bookings where phoneNumber matches the provided value
      const q = query(this._bookings, where("number", "==", phoneNumber));

      // Execute the query and get the matching documents
      const querySnapshot = await getDocs(q);

      // Extract and return the booking data
      const bookings = querySnapshot.docs.map((doc) => ({
        // ...doc.data(),
        bookingId: doc.data().id,
        propertyId: doc.data().propertyId,
        purpose: doc.data().purpose,
        bookedRooms: doc.data().bookedRooms,
        email: doc.data().email,
        checkInDate: doc.data().checkInDate,
        checkOutDate: doc.data().checkOutDate,
        name: doc.data().name,
        number: doc.data().number,
        noPeople: doc.data().noPeople,
        status: doc.data().status,
        paymentId: doc.data().paymentId,
      }));

      return bookings; // Return the array of bookings
    } catch (error) {
      console.error(`Error fetching bookings: ${error}`);
      return null;
    }
  }

  async fetchBookingBasedOnId({
    bookingId,
    phoneNumber,
  }: {
    bookingId: string;
    phoneNumber: string;
  }): Promise<BookingDataType | null> {
    try {
      // Get a reference to the booking document using the bookingId
      const docRef = doc(this._bookings, bookingId); // Firestore Client SDK method

      // Fetch the document from Firestore
      const docSnap = await getDoc(docRef);

      // Check if the document exists and extract the data
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Validate that the phone number matches the one in the document
        if (data.number === phoneNumber) {
          const bookingData = {
            bookingId: data.id,
            propertyId: data.propertyId,
            purpose: data.purpose,
            bookedRooms: data.bookedRooms,
            email: data.email,
            checkInDate: data.checkInDate,
            checkOutDate: data.checkOutDate,
            name: data.name,
            number: data.number,
            noPeople: data.noPeople,
            status: data.status,
            paymentId: data.paymentId,
          };

          return bookingData; // Return the booking data if phoneNumber matches
        } else {
          console.log(
            `Phone number does not match for booking ID: ${bookingId}`
          );
          return null; // Return null if phone number doesn't match
        }
      } else {
        console.log(`No booking found with ID: ${bookingId}`);
        return null; // Return null if no document found
      }
    } catch (error) {
      console.error(`Error fetching booking with ID: ${bookingId}`, error);
      return null;
    }
  }

  async getConfirmedBookingsForPropertyId(propertyId: string) {
    try {
      const fromDate = new Date(); // Gets current date
      fromDate.setHours(0, 0, 0, 0);
      const fromDateInString = fromDate.toISOString();
      const _bookings = collection(db, "bookings");
      const q = query(
        _bookings,
        where("propertyId", "==", propertyId),
        where("checkOutDate", ">", fromDateInString),
        where("status", "==", BookingStatus.CONFIRMED)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        return querySnapshot.docs.map((doc) => {
          return Bookings.fromJson(doc.data()); // Assuming Bookings has a fromJson method
        });
      } else {
        console.log(
          `No document found in the "bookings" collection with propertyID: ${propertyId}`
        );
        return [];
      }
    } catch (error) {
      console.error(
        `Error getting Booking Data from propertyID: ${propertyId} in Firestore: ${error}`
      );
      return [];
    }
  }

  fetchBookedRooms({
    checkInDate,
    checkOutDate,
    bookings,
  }: {
    checkInDate: Date;
    checkOutDate: Date;
    bookings: DocumentData[];
  }): string[] {
    try {
      const bookedRooms = new Set<string>(); // To store unique room IDs

      bookings.forEach((booking) => {
        const bookingCheckInDate = new Date(booking.checkInDate);
        const bookingCheckOutDate = new Date(booking.checkOutDate);

        // Get the range of dates between check-in and one day before check-out to avoid blocking check-out date
        const bookingDates = this.getDatesBetweenWithoutCheckOutDate(
          bookingCheckInDate,
          bookingCheckOutDate
        );

        // Filter only dates that overlap with the provided check-in and check-out range
        const filteredBookingDates = bookingDates.filter((date) => {
          return date >= checkInDate && date < checkOutDate; // Exclude checkOutDate
        });

        // If there is any overlapping date, add the rooms from the booking
        if (filteredBookingDates.length > 0) {
          booking.bookedRooms.forEach((roomId: string) => {
            bookedRooms.add(roomId);
          });
        }
      });

      // Return the array of booked room IDs
      return Array.from(bookedRooms);
    } catch (error) {
      console.error(`Error fetching booked rooms: ${error}`);
      return [];
    }
  }

  fetchBookedDaysOfRooms({
    roomIds: selectedRoomIds,
    bookings: bookings,
  }: {
    roomIds: string[];
    bookings: Bookings[];
  }): Date[] {
    try {
      const bookedDays = new Set<number>(); // To store unique booked days

      selectedRoomIds.forEach((roomId) => {
        bookings.forEach((booking) => {
          if (booking.bookedRooms.includes(roomId)) {
            const checkInDate = new Date(booking.checkInDate); // Assuming the dates are in a format that can be parsed by Date
            const checkOutDate = new Date(booking.checkOutDate);

            // Get all dates between check-in and check-out
            const datesBetween = this.getDatesBetweenWithoutCheckOutDate(
              checkInDate,
              checkOutDate
            );

            // Add all dates to the set (to avoid duplicates)
            datesBetween.forEach((date) => {
              bookedDays.add(date.getTime()); // Use timestamp for uniqueness
            });
          }
        });
      });

      // Convert the set of timestamps back to Date objects, sort the dates if needed
      return Array.from(bookedDays).map((timestamp) => new Date(timestamp));
    } catch (error) {
      console.error(`Error fetching booked days for rooms: ${error}`);
      return [];
    }
  }

  async isVillaBooked({
    propertyId,
    checkInDate,
    checkOutDate,
  }: {
    propertyId: string;
    checkInDate: Date;
    checkOutDate: Date;
  }): Promise<boolean> {
    try {
      const bookingsRef = collection(db, "bookings");

      const q = query(
        bookingsRef,
        where("propertyId", "==", propertyId),
        where("status", "==", BookingStatus.CONFIRMED),
        where("checkInDate", "<", checkOutDate.toISOString()),
        where("checkOutDate", ">", checkInDate.toISOString()),
        where("bookedRooms", "array-contains", propertyId)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // No overlapping bookings found; villa is available
        return true;
      }

      for (const doc of querySnapshot.docs) {
        const booking = doc.data();

        const bookingCheckInDate = new Date(booking.checkInDate);
        const bookingCheckOutDate = new Date(booking.checkOutDate);

        // Check if the dates are exactly equal
        const isExactMatch =
          bookingCheckInDate.getTime() === new Date(checkOutDate).getTime() ||
          bookingCheckOutDate.getTime() === new Date(checkInDate).getTime();

        return isExactMatch;
      }

      // No bookings found where propertyId is booked during overlapping dates
      return true; // Villa is available
    } catch (error) {
      console.error(`Error checking villa booking: ${error}`);
      throw error;
    }
  }

  // Helper method to generate dates between two dates (exclusive of endDate)
  private getDatesBetweenWithoutCheckOutDate(
    startDate: Date,
    endDate: Date
  ): Date[] {
    const dates: Date[] = [];
    let currentDate = new Date(startDate);
    // Exclude the check-out date
    while (currentDate < endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }
}

export default BookingDetailsFirebaseImpl;
