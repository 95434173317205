"use client";

import { DisplayText } from "@/components/common/texts/display-text";
import { HeadlineText } from "@/components/common/texts/headline-text";
import { LabelText } from "@/components/common/texts/label-text";
import Image from "next/image";
import { useSearchParams } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import { SinglePhotoModal } from "./single-photo-modal";
import Loading from "@/components/loading";
import StickyBox from "react-sticky-box";
import { FaArrowUp } from "react-icons/fa6";
import * as gtag from "./../../../../../lib/gtag";

export const Photos = ({
  photos,
  id
}: {
  id: string;
  photos: Record<string, string[]>;
}) => {
  const searchParams = useSearchParams();
  const singlePhoto = searchParams.get("singlePhoto");
  const photoCategory = searchParams.get("category");
  const [isModalOpen, setIsModalOpen] = useState(!!singlePhoto);
  const [image, setImage] = useState<null | {
    photo: string;
    category: string;
  }>(null);

  const handleScroll = (element: string) => {
    const section = document.getElementById(element)!;
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const getToTop = () => {
    const section = document.getElementById("photo-tour-section")!;
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleClick = ({
    photo,
    category,
  }: {
    photo: string;
    category: string;
  }) => {
    const currentParams = new URLSearchParams(searchParams);
    currentParams.set("singlePhoto", photo);
    currentParams.set("category", category);
    setImage({ photo, category });

    const newQueryString = currentParams.toString();
    const newUrl = `${window.location.pathname}?${newQueryString}`;

    window.history.pushState({}, "", newUrl);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const handlePopState = () => {
      const updatedSearchParams = new URLSearchParams(window.location.search);
      const singlePhoto = updatedSearchParams.get("singlePhoto");
      setIsModalOpen(!!singlePhoto);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (singlePhoto && photoCategory) {
      setImage({ photo: singlePhoto, category: photoCategory });
    }
  }, [photoCategory, singlePhoto]);

  useEffect(() => {
    const waitChat = document.getElementById("whatsapp-chat-widget");
    waitChat && (waitChat.style.display = "none");
  }, []);

  useEffect(() => {
    gtag.event({
      action: `${id}_showphotos`,
      category: 'engagement',
      label: 'image slided',
      value: 1,
    });
  }, []);

  return (
    <div
      id="photo-tour-section"
      className="w-full h-full relative pt-14 mobile:pt-5"
    >
      <div className="w-full h-full text-black">
        <div className="w-full mobile:w-full flex flex-col items-start mx-auto">
          <div className="w-full flex flex-col gap-y-8 mobile:gap-y-4 mobile:px-0 px-[75px]">
            <DisplayText className="mobile:ml-7 text-primaryB7 !text-[48px] !leading-[50px] !font-bold mobile:!text-[38px] mobile:!font-semibold mobile:!leading-[38px]">
              Photo tour
            </DisplayText>
            <div className="w-full mobile:overflow-x-auto">
              <div className="w-full grid grid-cols-10 gap-x-[18.5px] gap-y-5 mobile:w-fit mobile:gap-x-2 mobile:justify-center mobile:flex mobile:gap-y-0 mobile:flex-nowrap">
                {Object.keys(photos).map((item, index) => {
                  if (
                    item.includes("Bathroom") ||
                    (item.includes("roomOrder") && item.length > 8)
                  )
                    return;
                  return (
                    <button
                      onClick={handleScroll.bind(null, item)}
                      key={item + index}
                      className={`${index === 0 && "mobile:ml-7"} ${
                        index === Object.keys(photos).length - 1 &&
                        "mobile:mr-7"
                      } flex flex-col items-start gap-y-1.5 mobile:w-[135px]`}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${
                            photos?.[item]?.[0]! as string
                          })`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                        }}
                        className="w-full h-[100px] object-cover relative"
                      >
                        <div className="photos_modal_image"></div>
                      </div>
                      <LabelText className="mobile:ml-0.5 line-clamp-1 text-primaryB7">
                        {item}
                      </LabelText>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="relative mobile:px-7 px-[75px] flex flex-col gap-y-10 w-full mt-14 mobile:mt-10">
            {Object.keys(photos).map((item, index) => {
              if (
                item.includes("Bathroom") ||
                (item.includes("roomOrder") && item.length > 8)
              )
                return;
              return (
                <section
                  id={item}
                  key={item + index}
                  className="w-full h-auto flex items-start justify-between gap-x-16 mobile:flex-col mobile:gap-y-2"
                >
                  <StickyBox
                    offsetTop={100}
                    className="-mt-5 w-[30%] h-[100px] mobile:hidden"
                  >
                    <div className="w-full h-full">
                      <HeadlineText className="text-primaryB7">
                        {item}
                      </HeadlineText>
                    </div>
                  </StickyBox>
                  <HeadlineText className="text-primaryB7 hidden mobile:block">
                    {item}
                  </HeadlineText>
                  <div className="w-[70%] mobile:w-full ml-auto mobile:mr-auto gap-2 grid grid-cols-2">
                    {photos?.[item].map((photo, index) => {
                      return (
                        <button
                          key={photo + index}
                          onClick={() => handleClick({ photo, category: item })}
                        >
                          <Image
                            width={1000}
                            height={1000}
                            src={photo! as string}
                            alt="Property Photo"
                            className="w-[500px] h-[300px] mobile:w-[400px] mobile:h-[200px] object-cover"
                          />
                        </button>
                      );
                    })}
                  </div>
                </section>
              );
            })}
          </div>

          <button
            onClick={getToTop}
            className="sticky bottom-6 mb-5 left-[100%] mr-[25px] bg-white rounded-full h-12 w-12 mobile:h-10 mobile:w-10 flex items-center justify-center border border-primaryB7"
          >
            <FaArrowUp size={25} color="#51311f" />
          </button>
        </div>
      </div>

      {isModalOpen && singlePhoto && image && (
        <Suspense fallback={<Loading />}>
          <SinglePhotoModal
            photos={photos}
            image={image?.photo}
            category={image.category}
          />
        </Suspense>
      )}
    </div>
  );
};
