"use client";

import {
  HeroPropertyType,
  SortedProperty,
} from "../../../../types/properties_type";
import { ListingCard } from "../../../../components/listingCard";
import { useTagsData } from "@/hooks/use-tags-data";
import { usePropertyAvgRating } from "@/hooks/use-property-avg-rating";
import { dateDifference, today, tomorrow } from "@/lib/search-data";
import { useEffect, useState } from "react";
import { useSetProperties } from "@/hooks/use-set-properties";
import { getTag, shuffle } from "@/lib/utils";
import { SearchDataType } from "@/types/common";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { getListingPagePrices } from "@/lib/prices-data";
import { format } from "date-fns";

interface Props {
  propertiesData: HeroPropertyType[];
  isRelatedProperties?: boolean;
  city?: string;
}

export const Listings = ({
  propertiesData,
  isRelatedProperties,
  city,
}: Props) => {
  const [searchData, setSearchData] = useState<SearchDataType>({
    guests: {
      noOfChildren: 0,
      noOfAdults: 0,
      noOfPets: 0,
      noOfTotalGuests: 0,
    },
    checkInDate: null,
    checkOutDate: null,
    city: "",
  });
  const [sortedProperties, setSortedProperties] = useState<HeroPropertyType[]>(
    []
  );

  const {
    checkInDate,
    checkOutDate,
    guests: { noOfTotalGuests },
  } = searchData;

  const checkIn = checkInDate ? checkInDate : today;
  const checkOut = checkOutDate ? checkOutDate : tomorrow;
  const guests = noOfTotalGuests ? noOfTotalGuests : 4;

  useEffect(() => {
    (async () => {
      try {
        const listingPrices = await getListingPagePrices({
          checkIn: format(checkIn, "yyyy-MM-dd"),
          checkOut: format(checkOut, "yyyy-MM-dd"),
          noOfGuests: guests,
          city: searchData.city
            ? searchData.city
            : city
            ? city
            : propertiesData[0].city,
        });
        const dateDiff = dateDifference(checkIn, checkOut);

        // Filter properties and add pricing information
        const propertiesWithPricing = propertiesData
          .map((property) => {
            const propertySlug = property.propertyId;
            const pricingInfo = listingPrices[propertySlug];
            const total_price = pricingInfo.total_price;
            const discounted_price = pricingInfo.discounted_price;
            let discountPercentage = 0;
            if (total_price > 0) {
              discountPercentage =
                ((total_price - discounted_price) / total_price) * 100;
              discountPercentage = Math.round(discountPercentage * 100) / 100;
            }

            if (pricingInfo && pricingInfo.isAvailable) {
              return {
                ...property,
                total_price: +(total_price / dateDiff).toFixed(0),
                discounted_price: +(discounted_price / dateDiff).toFixed(0),
                discount_percentage: +discountPercentage.toFixed(0),
              };
            }
            return null;
          })
          .filter(Boolean) as HeroPropertyType[];

        // Update sorted properties state
        setSortedProperties(propertiesWithPricing);
      } catch (error) {
        console.error("Error fetching listing prices:", error);
        // Fallback to showing properties without pricing
        setSortedProperties(propertiesData);
      }
    })();
  }, [searchData, city, checkIn, guests, checkOut]);

  useLocalStorageSearchData({ setSearchData });

  return (
    <div className="mt-5">
      <div
        className={`px-1 py-1 ${
          isRelatedProperties && "related-properties-container"
        }`}
      >
        <div
          className={`flex gap-x-6 ${
            isRelatedProperties &&
            "!w-fit !justify-center !flex mr-[100px] mobile:mr-[30px]"
          }`}
        >
          {sortedProperties.map((item, index) => (
            <ListingCard
              avgRating={item.avgRating}
              tag={getTag({ tag: item.tag })}
              isRelatedProperties
              index={index}
              key={item.propertyId}
              property={item}
              isPadding
            />
          ))}
        </div>
      </div>
    </div>
  );
};
