import { RoomType } from "@/types/room_type";
import { create } from "zustand";

interface UpdateRoomsState {
  rooms: RoomType[];
  updateRooms: (newRooms: RoomType[]) => void;
  isBooked: boolean;
  setIsBooked: (isBooked: boolean) => void;
}

export const useUpdateRooms = create<UpdateRoomsState>((set) => {
  return {
    rooms: [],
    updateRooms: (newRooms: RoomType[]) =>
      set(() => ({
        rooms: newRooms,
      })),
    isBooked: false,
    setIsBooked: (isBooked) =>
      set(() => ({
        isBooked,
      })),
  };
});
