export const zeroOutTime = (dateStr: string | Date): string => {
  const date = new Date(dateStr);

  // Format the date as "YYYY-MM-DD 00:00:00.000Z"
  const year = date.getUTCFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day} 00:00:00.000Z`;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export function formatIndianCurrency(number: number): string {
  return number.toLocaleString("en-IN");
}

export const getDiscountPercentage = ({
  originalPrice,
  salePrice,
}: {
  originalPrice: number;
  salePrice: number;
}) => {
  const discountAmount = originalPrice - salePrice;

  const discountPercentage = (discountAmount / originalPrice) * 100;

  return +(Math.round(discountPercentage * 100) / 100).toFixed(0);
};
