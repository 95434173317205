import { v4 as uuidv4 } from "uuid";
import { zeroOutTime } from "../utility";
import { formatDate } from "date-fns";

export class Bookings {
  noPeople: number;
  bookedRooms: string[];
  paymentId?: string;
  checkInDate: string;
  checkOutDate: string;
  name: string;
  lastName: string;
  email: string;
  number: string;
  country: string;
  purpose: string;
  id: string = uuidv4();
  status: string;
  propertyId: string;
  breakfastAmount: number;
  createdAt: Date;

  constructor({
    noPeople,
    bookedRooms,
    paymentId,
    checkInDate,
    checkOutDate,
    name,
    lastName,
    email,
    number,
    country,
    purpose,
    status,
    propertyId,
    breakfastAmount,
    createdAt,
  }: {
    noPeople: number;
    bookedRooms: string[];
    paymentId?: string;
    checkInDate: string;
    checkOutDate: string;
    name: string;
    lastName: string;
    email: string;
    number: string;
    country: string;
    purpose: string;
    status: string;
    propertyId: string;
    breakfastAmount: number;
    createdAt: Date;
  }) {
    this.noPeople = noPeople;
    this.bookedRooms = bookedRooms;
    this.paymentId = paymentId;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.name = name;
    this.lastName = lastName;
    this.email = email;
    this.number = number;
    this.country = country;
    this.purpose = purpose;
    this.status = status;
    this.propertyId = propertyId;
    this.breakfastAmount = breakfastAmount;
    this.createdAt = createdAt;
  }

  static fromJson(json: { [key: string]: any }): Bookings {
    return new Bookings({
      noPeople: json.noPeople,
      bookedRooms: json.bookedRooms,
      paymentId: json.paymentId,
      checkInDate: json.checkInDate,
      checkOutDate: json.checkOutDate,
      name: json.name,
      lastName: json.lastName,
      email: json.email,
      number: json.number,
      country: json.country,
      purpose: json.purpose,
      status: json.status,
      propertyId: json.propertyId,
      breakfastAmount: json.breakfastAmount,
      createdAt: json.createdAt,
    });
  }

  toJson(): { [key: string]: any } {
    return {
      noPeople: this.noPeople,
      bookedRooms: this.bookedRooms,
      paymentId: this.paymentId,
      checkInDate: zeroOutTime(this.checkInDate),
      checkOutDate: zeroOutTime(this.checkOutDate),
      name: this.name,
      lastName: this.lastName,
      email: this.email,
      number: this.number,
      country: this.country,
      purpose: this.purpose,
      id: this.id,
      status: this.status,
      propertyId: this.propertyId,
      breakfastAmount: this.breakfastAmount,
      createdAt: this.createdAt,
    };
  }

  toString(): string {
    return `Bookings{noPeople: ${this.noPeople}, bookedRooms: ${this.bookedRooms}, paymentId: ${this.paymentId}, checkInDate: ${this.checkInDate}, checkOutDate: ${this.checkOutDate}, name: ${this.name}, lastName: ${this.lastName}, email: ${this.email}, number: ${this.number}, country: ${this.country}, purpose: ${this.purpose}, id: ${this.id}, status: ${this.status}, propertyId: ${this.propertyId}}`;
  }

  toGptServer(
    villaName: string,
    roomNames: string[],
    price: number,
    bookingId: string,
    totalAmount: number,
    nOfBreakfast: number,
    nOfGuests: number,
    noOfChildren: number,
    noOfPets: number,
    stayPurpose: string,
    paymentStatus: string
  ): { [key: string]: any } {
    return {
      id: bookingId,
      noPeople: this.noPeople,
      checkIn: formatDate(this.checkInDate, "dd MMM, yyyy"),
      checkout: formatDate(this.checkOutDate, "dd MMM, yyyy"),
      name: this.name,
      villaName: villaName,
      roomNames: roomNames,
      price: price,
      phoneNumber: this.number,
      remainingAmount: totalAmount - price,
      nOfBreakfast,
      nOfGuests,
      noOfChildren,
      noOfPets,
      stayPurpose,
      bookingStatus: "PENDING APPROVAL",
      paymentStatus,
    };
  }
}
