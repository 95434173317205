import Image from "next/image";
import { Lexend_Deca } from "next/font/google";

const lexendDeca = Lexend_Deca({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

interface Props {
  text: string;
  iconImage: string;
  tagId: string;
}

export const ListingTicker = ({ text, iconImage, tagId }: Props) => {
  return (
    <div
      style={{
        boxShadow:
          "0px -40px 11px 0px rgba(0, 0, 0, 0.00), 0px -25px 10px 0px rgba(0, 0, 0, 0.01), 0px -14px 9px 0px rgba(0, 0, 0, 0.05), 0px -6px 6px 0px rgba(0, 0, 0, 0.09), 0px -2px 3px 0px rgba(0, 0, 0, 0.10)",
      }}
      className={`${tagId} flex items-center justify-center gap-x-2.5 absolute w-full bottom-0 z-[10] p-[6px_10px_6px_10px]`}
    >
      <Image
        width={100}
        height={100}
        className="w-[13.5px] h-[12px] object-contain"
        alt={text}
        src={iconImage}
      />
      <span
        style={{ letterSpacing: "2.5px" }}
        className={`${lexendDeca.className} text-[12px] font-normal uppercase !text-white`}
      >
        {text}
      </span>
    </div>
  );
};
