export const CloseIcon = ({ color }: { color: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.2479 2.98437C20.0498 2.98905 19.8617 3.0719 19.7245 3.21484L11.9999 10.9394L4.27526 3.21484C4.20515 3.1429 4.12131 3.08578 4.02871 3.04687C3.93612 3.00795 3.83664 2.98802 3.7362 2.98828C3.58722 2.98871 3.44175 3.03349 3.31833 3.11691C3.1949 3.20033 3.09911 3.31862 3.04317 3.45669C2.98723 3.59476 2.97367 3.74636 3.00422 3.89217C3.03477 4.03798 3.10805 4.17138 3.21471 4.27539L10.9393 12L3.22448 19.7148C3.1525 19.7839 3.09503 19.8667 3.05545 19.9583C3.01586 20.0499 2.99494 20.1485 2.99393 20.2483C2.99292 20.3481 3.01182 20.447 3.04954 20.5394C3.08725 20.6318 3.14302 20.7157 3.21358 20.7863C3.28414 20.8568 3.36807 20.9126 3.46045 20.9503C3.55283 20.988 3.65181 21.007 3.75159 21.0059C3.85137 21.0049 3.94995 20.984 4.04154 20.9444C4.13314 20.9048 4.21592 20.8474 4.28502 20.7754L11.9999 13.0605L19.7167 20.7793C19.7864 20.8491 19.8692 20.9044 19.9604 20.9422C20.0516 20.98 20.1493 20.9994 20.2479 20.9994C20.3466 20.9994 20.4443 20.98 20.5355 20.9422C20.6266 20.9045 20.7095 20.8491 20.7792 20.7794C20.849 20.7096 20.9043 20.6268 20.9421 20.5356C20.9799 20.4444 20.9993 20.3467 20.9993 20.2481C20.9993 20.1494 20.9798 20.0517 20.9421 19.9605C20.9043 19.8694 20.8489 19.7866 20.7792 19.7168L13.0604 12L20.785 4.27539C20.8941 4.17071 20.969 4.03552 20.9999 3.88754C21.0308 3.73955 21.0162 3.58569 20.9582 3.44612C20.9001 3.30654 20.8012 3.18777 20.6745 3.10538C20.5477 3.02298 20.399 2.9808 20.2479 2.98437Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};
