import { useEffect, useState } from "react";

export const useIsMobile = ({ isDesktop }: { isDesktop?: boolean }) => {
  const [isMobile, setIsMobile] = useState(isDesktop ? false : true);

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth > 475 ? setIsMobile(false) : setIsMobile(true);
    };
    window.addEventListener("resize", () => handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};
