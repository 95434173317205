export const MobileBottomBarCloseIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6554 0.865365C11.5316 0.868289 11.414 0.920072 11.3283 1.00941L6.50041 5.83729L1.67253 1.00941C1.62871 0.964449 1.57631 0.928749 1.51844 0.904424C1.46056 0.8801 1.39839 0.867648 1.33561 0.867807C1.2425 0.868075 1.15158 0.896062 1.07444 0.948201C0.997302 1.00034 0.937434 1.07427 0.902471 1.16056C0.867508 1.24686 0.859034 1.34161 0.878128 1.43274C0.897222 1.52387 0.94302 1.60725 1.00968 1.67225L5.83756 6.50013L1.01579 11.3219C0.9708 11.3651 0.934884 11.4168 0.910142 11.4741C0.8854 11.5313 0.872329 11.5929 0.871695 11.6553C0.87106 11.7177 0.882876 11.7795 0.906449 11.8373C0.930022 11.895 0.964879 11.9475 1.00898 11.9916C1.05308 12.0357 1.10553 12.0705 1.16327 12.0941C1.22101 12.1177 1.28287 12.1295 1.34523 12.1288C1.4076 12.1282 1.46921 12.1151 1.52645 12.0904C1.5837 12.0657 1.63544 12.0297 1.67863 11.9848L6.50041 7.16297L11.3234 11.9872C11.367 12.0308 11.4188 12.0654 11.4757 12.089C11.5327 12.1126 11.5938 12.1248 11.6554 12.1248C11.7171 12.1248 11.7782 12.1126 11.8352 12.089C11.8921 12.0654 11.9439 12.0308 11.9875 11.9872C12.0311 11.9436 12.0657 11.8919 12.0893 11.8349C12.1129 11.7779 12.125 11.7168 12.125 11.6552C12.125 11.5935 12.1129 11.5324 12.0893 11.4755C12.0657 11.4185 12.0311 11.3667 11.9875 11.3231L7.16325 6.50013L11.9911 1.67225C12.0593 1.60683 12.1061 1.52233 12.1254 1.42984C12.1447 1.33736 12.1356 1.24119 12.0993 1.15396C12.063 1.06672 12.0012 0.992492 11.922 0.940994C11.8428 0.889495 11.7499 0.863134 11.6554 0.865365Z"
        fill="#65605A"
        stroke="#65605A"
      />
    </svg>
  );
};
