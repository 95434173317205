export const SearchPageLongStayIcon = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3863_30309"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <rect width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3863_30309)">
        <path
          d="M2.5 25V12.5L10 5L17.5 12.5V25H2.5ZM5 22.5H8.75V18.75H11.25V22.5H15V13.5312L10 8.53125L5 13.5312V22.5ZM8.75 16.25V13.75H11.25V16.25H8.75ZM20 25V11.4688L13.5312 5H17.0625L22.5 10.4375V25H20ZM25 25V9.40625L20.5938 5H24.125L27.5 8.375V25H25Z"
          fill="#3EA920"
        />
      </g>
    </svg>
  );
};
