"use client";

import { AmenityType } from "../../../types/properties_type";
import Image from "next/image";
import { TitleText } from "@/components/common/texts/title-text";
import { BodyText } from "@/components/common/texts/body-text";
import { FormEvent, useState } from "react";
import "./amenities-section.css";
import { LabelText } from "@/components/common/texts/label-text";
import { NeutralButton } from "@/components/common/buttons/neutral-button";
import * as gtag from "./../../../../lib/gtag";

interface Props {
  amenities: string[];
  allAmenities: AmenityType[];
  id: string
}

export const AmenitiesSection = ({ amenities, allAmenities, id }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const amenitiesWithIcons = amenities.map((item) => {
    return allAmenities.find((i) => i.id === item);
  });

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
    gtag.event({
      action: `${id}_read_show_all_amenities`,
      category: 'details_engagement',
      label: 'show all amenities pressed',
      value: 1,
    });
  };

  return (
    <section className="w-full mobile:w-full mobile:mx-auto mobile:px-[20px]">
      <div className="flex flex-col gap-y-[30px]">
        <TitleText className="text-primaryB7 mobile:!text-[24px] mobile:!font-semibold">
          what the property offers
        </TitleText>
        <div className="flex flex-col gap-y-8 mobile:gap-y-5">
          <div
            className={`${
              isExpanded ? "expanded-amenities" : "less-amenities"
            } w-full mobile:w-full mobile:pb-0 grid grid-cols-2 mobile:gap-y-5 mobile:grid-cols-2 justify-between gap-y-6 mobile:border-0`}
          >
            {amenitiesWithIcons.map((item, index) => (
              <div
                key={item ? item.id + index : index}
                className="flex items-center gap-x-4"
              >
                <Image
                  src={item?.icon!}
                  alt={item?.label!}
                  width={100}
                  height={100}
                  className="w-7 mobile:w-6 h-7 mobile:h-6 object-cover"
                />
                <BodyText className="text-neutralN7">{item?.label}</BodyText>
              </div>
            ))}
          </div>
          <button className="self-start" onClick={toggleExpanded}>
            <NeutralButton
              link="#"
              onClick={(event: FormEvent) => event.preventDefault()}
            >
              <LabelText className="text-primaryB7 mobile:!font-normal mobile:!text-[14px]">
                {isExpanded ? "show less amenities" : "show all amenities →"}
              </LabelText>
            </NeutralButton>
          </button>
        </div>
      </div>
    </section>
  );
};
