"use client";

import { useEffect, useMemo, useState } from "react";
import { SelectedRoom } from "../../../../types/room_type";
import { BookingSummary } from "./booking-summary";
import { LeftSection } from "./left-section";
import { RoomType } from "../../../../../lib/classes/room-type-class";
import { AmenityType } from "../../../../types/properties_type";
import "./index.css";
import Sticky from "react-sticky-box";
import BookingDetailsFirebaseImpl from "../../../../../data/booking_data";
import { Bookings } from "../../../../../lib/classes/bookings-class";
import {
  AboutPropertyType,
  COUPON_TYPES,
  SearchDataType,
} from "@/types/common";
import { today, tomorrow } from "@/lib/search-data";
import { useUpdatePrices } from "@/store/update-prices";
import { useUpdateCouponsData } from "@/hooks/use-update-coupons-data";
import {
  isEarlyBird,
  isLongStay,
  isRushDeal,
  updateCouponPrices,
} from "@/lib/coupons";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";
import { OffersSection } from "../offers-section";
import ModalOverlay from "@/components/modal";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { useUpdateSearchPageCoupons } from "@/store/update-search-page-coupons";
import { useSearchParams, useRouter } from "next/navigation";

interface Props {
  id: string;
  rooms: RoomType[];
  propertyId: string;
  price: number;
  description: string;
  amenities: string[];
  allAmenities: AmenityType[];
  mainDescription?: string;
  noOfBeds: number;
  noOfBedrooms: number;
  noOfGuests: number;
  noOfBathrooms: number;
  location: string;
  name: string;
  images: string[];
  address: string;
  locationDescription: string;
  lat: number;
  lng: number;
  aboutProperty: AboutPropertyType;
  baseOccupancy: number;
  redirectionLink: string;
  maxOccupancy: number;
  city: string;
  offers?: boolean;
}

export const MiddleSection = ({
  id,
  rooms,
  propertyId,
  price: propertyPrice,
  description,
  allAmenities,
  amenities,
  mainDescription,
  noOfBathrooms,
  noOfBedrooms,
  noOfGuests,
  noOfBeds,
  location,
  name,
  images,
  lat,
  lng,
  address,
  locationDescription,
  aboutProperty,
  baseOccupancy,
  redirectionLink,
  maxOccupancy,
  city,
  offers,
}: Props) => {
  const [selectedRooms, setSelectedRooms] = useState<null | SelectedRoom[]>(
    null
  );
  const { updateCoupons } = useUpdateSearchPageCoupons((state) => state);
  const [bookingsOfThisProperty, setBookingsOfthisProperty] = useState<
    Bookings[]
  >([]);
  const [blockedDates, setBlockedDates] = useState<undefined | Date[]>(
    undefined
  );
  const {
    updateAppliedCoupons,
    updateValidPropertyCoupons,
    updateDiscountedCoupons,
  } = useUpdatePropertyCoupons((state) => state);
  const {
    allPrices,
    updatePrice,
    updateTotalDiscount,
    updateDefaultPropertyPrice,
    updateCutThroughPricePerNight,
    updateCutThroughPrice,
    updateVillaPricePerNight,
  } = useUpdatePrices((state) => state);
  const [bookedRooms, setBookedRooms] = useState<string[]>([]);
  const [searchData, setSearchData] = useState<SearchDataType>({
    guests: {
      noOfChildren: 0,
      noOfAdults: 0,
      noOfPets: 0,
      noOfTotalGuests: 0,
    },
    checkInDate: null,
    checkOutDate: null,
    city: "",
  });
  const isMobile = useIsMobile({});
  const searchParams = useSearchParams();
  const { replace } = useRouter();

  const fullVillaSelected = selectedRooms?.find((room) => room.isVilla);
  const maxGuests = fullVillaSelected
    ? maxOccupancy
    : selectedRooms
    ? selectedRooms.length * 2
    : 0;

  const handleSelectedRoom = (rooms: SelectedRoom[] | null) => {
    setSelectedRooms(rooms);
  };

  const bookingDataService = useMemo(
    () => new BookingDetailsFirebaseImpl(),
    []
  );

  const handleCloseOffers = () => {
    const params = new URLSearchParams(searchParams);
    params.delete("offers");
    replace(`?${params.toString()}`);
  };

  useEffect(() => {
    if (bookingsOfThisProperty.isEmpty) return;
    setBlockedDates(undefined);
    setBookedRooms([]);

    (async () => {
      if (selectedRooms) {
        const res = await fetch(
          `${process.env.NEXT_PUBLIC_SAVINGZ_GPT_SERVER}/blocked-dates/${id}`
        );
        const data = await res.json();

        const bookedDates = data
          ? data.dates.map((dateString: string) => new Date(dateString))
          : [];

        setBlockedDates(bookedDates);
      }

      if (searchData.checkInDate && searchData.checkOutDate) {
        const bookedRooms = bookingDataService.fetchBookedRooms({
          bookings: bookingsOfThisProperty,
          checkInDate: searchData.checkInDate,
          checkOutDate: searchData.checkOutDate,
        });
        setBookedRooms(bookedRooms);
      }
    })();
  }, [
    selectedRooms,
    searchData.checkInDate,
    searchData.checkOutDate,
    bookingsOfThisProperty,
    bookingDataService,
    id,
  ]);

  useEffect(() => {
    if (searchData.checkInDate && searchData.checkOutDate) {
      const coupons = [];
      const { checkInDate, checkOutDate } = searchData;
      if (isEarlyBird({ checkInDate })) coupons.push(COUPON_TYPES.EARLY_BIRD);
      if (isLongStay({ checkInDate, checkOutDate }))
        coupons.push(COUPON_TYPES.LONG_STAY);
      if (isRushDeal({ checkInDate })) coupons.push(COUPON_TYPES.LAST_MINUTE);

      updateCoupons(coupons);
    }
  }, [searchData, updateCoupons]);

  useEffect(() => {
    (async () => {
      const bookingImpl = new BookingDetailsFirebaseImpl();
      const bookings = await bookingImpl.getConfirmedBookingsForPropertyId(
        propertyId
      );
      setBookingsOfthisProperty(bookings);
    })();
  }, [
    propertyId,
    id,
    searchData.checkInDate,
    searchData.checkOutDate,
    propertyPrice,
  ]);

  useEffect(() => {
    const data = localStorage.getItem("appliedCoupons");
    if (data) {
      const coupons = JSON.parse(data);
      updateAppliedCoupons(coupons);
    }
  }, [updateAppliedCoupons]);

  useEffect(() => {
    const isFullVilla =
      !selectedRooms || selectedRooms.length === 0
        ? true
        : selectedRooms?.find((item) => item.isVilla === true)
        ? true
        : false;
    if (allPrices) {
      const {
        guests: { noOfTotalGuests },
        checkInDate,
        checkOutDate,
      } = searchData;
      const checkIn = checkInDate && checkOutDate ? checkInDate : today;
      const checkOut = checkInDate && checkOutDate ? checkOutDate : tomorrow;
      const noOfGuests = noOfTotalGuests ? noOfTotalGuests : 4;

      updateCouponPrices({
        checkIn,
        checkOut,
        noOfGuests,
        allPrices,
        selectedRooms,
        defaultPropertyPrice: propertyPrice,
        isFullVilla,
        updateCutThroughPrice,
        updateCutThroughPricePerNight,
        updatePrice,
        updateTotalDiscount,
        updateVillaPricePerNight,
        updateDiscountedCoupons,
      });
    }
  }, [
    allPrices,
    searchData,
    selectedRooms,
    updatePrice,
    updateTotalDiscount,
    updateCutThroughPrice,
    updateCutThroughPricePerNight,
    updateVillaPricePerNight,
    propertyPrice,
  ]);

  useEffect(() => {
    updateDefaultPropertyPrice(+propertyPrice);
  }, [propertyPrice, updateDefaultPropertyPrice]);

  useEffect(() => {
    localStorage.removeItem("appliedCoupons");
    localStorage.removeItem("referralCode");
    updateAppliedCoupons([]);
    updateValidPropertyCoupons([]);
  }, [updateAppliedCoupons, updateValidPropertyCoupons]);

  useUpdateCouponsData({ searchData, id, propertyId });

  if (offers) {
    const isFullVilla = selectedRooms?.find((item) => item.isVilla)
      ? true
      : false;

    if (!isMobile) {
      return (
        <section className="mt-5 mb-12 mobile:!my-0 w-full flex !items-start mobile:gap-x-0 relative mobile:h-auto">
          <LeftSection
            aboutProperty={aboutProperty}
            id={id}
            price={propertyPrice}
            propertyId={propertyId}
            getSelectedRooms={handleSelectedRoom}
            description={description}
            allAmenities={allAmenities}
            amenities={amenities}
            mainDescription={mainDescription ? mainDescription : description}
            noOfBathrooms={noOfBathrooms}
            noOfBedrooms={noOfBedrooms}
            noOfBeds={noOfBeds}
            noOfGuests={noOfGuests}
            location={location}
            name={name}
            images={images}
            bookedRooms={bookedRooms}
            bookedDates={blockedDates}
            setSearchData={setSearchData}
            searchData={searchData}
            address={address}
            lat={lat}
            lng={lng}
            locationDescription={locationDescription}
            baseOccupancy={baseOccupancy}
            redirectionLink={redirectionLink}
            maxOccupancy={maxOccupancy}
            city={city}
            rooms={rooms}
          />
          <Sticky className="w-[35%]" offsetTop={100} offsetBottom={20}>
            <BookingSummary
              propertyName={name}
              selectedRooms={selectedRooms}
              propertyId={propertyId}
              setSearchData={setSearchData}
              searchData={searchData}
              bookedDates={blockedDates}
              id={id}
              baseOccupancy={baseOccupancy}
              maxOccupancy={maxOccupancy}
              city={city}
            />
          </Sticky>

          <ModalOverlay
            onClose={handleCloseOffers}
            className="!p-0 !m-0 !z-[70] !h-[600px] !w-[600px]"
            overlayClassName="!z-[60]"
            childrenClassName="!p-0 !bg-neutralN2 !rounded-[5px] border border-primaryB6"
          >
            <OffersSection
              onClose={handleCloseOffers}
              city={city}
              roomIds={selectedRooms ? selectedRooms?.map((r) => r.roomId) : []}
              roomNames={
                selectedRooms ? selectedRooms?.map((r) => r.roomName) : []
              }
              propertyName={name}
              setSearchData={setSearchData}
              baseOccupancy={baseOccupancy}
              propertyId={id}
              rooms={rooms}
              isFullVilla={isFullVilla}
              searchData={searchData}
              maxOccupancy={maxGuests}
              bookedDates={blockedDates}
              defaultPropertyPrice={propertyPrice}
            />
          </ModalOverlay>
        </section>
      );
    }

    return (
      <OffersSection
        onClose={handleCloseOffers}
        city={city}
        roomIds={selectedRooms ? selectedRooms?.map((r) => r.roomId) : []}
        roomNames={selectedRooms ? selectedRooms?.map((r) => r.roomName) : []}
        propertyName={name}
        setSearchData={setSearchData}
        baseOccupancy={baseOccupancy}
        propertyId={id}
        rooms={rooms}
        isFullVilla={isFullVilla}
        searchData={searchData}
        maxOccupancy={maxGuests}
        bookedDates={blockedDates}
        defaultPropertyPrice={propertyPrice}
      />
    );
  }

  return (
    <section className="mt-5 mb-12 mobile:!my-0 w-full flex !items-start mobile:gap-x-0 relative mobile:h-auto">
      <LeftSection
        aboutProperty={aboutProperty}
        id={id}
        price={propertyPrice}
        propertyId={propertyId}
        getSelectedRooms={handleSelectedRoom}
        description={description}
        allAmenities={allAmenities}
        amenities={amenities}
        mainDescription={mainDescription ? mainDescription : description}
        noOfBathrooms={noOfBathrooms}
        noOfBedrooms={noOfBedrooms}
        noOfBeds={noOfBeds}
        noOfGuests={noOfGuests}
        location={location}
        name={name}
        images={images}
        bookedRooms={bookedRooms}
        bookedDates={blockedDates}
        setSearchData={setSearchData}
        searchData={searchData}
        address={address}
        lat={lat}
        lng={lng}
        locationDescription={locationDescription}
        baseOccupancy={baseOccupancy}
        redirectionLink={redirectionLink}
        maxOccupancy={maxOccupancy}
        city={city}
        rooms={rooms}
      />
      <Sticky className="w-[35%]" offsetTop={100} offsetBottom={20}>
        <BookingSummary
          propertyName={name}
          selectedRooms={selectedRooms}
          propertyId={propertyId}
          setSearchData={setSearchData}
          searchData={searchData}
          bookedDates={blockedDates}
          id={id}
          baseOccupancy={baseOccupancy}
          maxOccupancy={maxOccupancy}
          city={city}
        />
      </Sticky>
    </section>
  );
};
