export const OffersModalCloseIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6545 2.36537C12.5307 2.36829 12.4131 2.42007 12.3273 2.50941L7.49943 7.33729L2.67155 2.50941C2.62773 2.46445 2.57533 2.42875 2.51746 2.40442C2.45958 2.3801 2.39741 2.36765 2.33463 2.36781C2.24153 2.36807 2.15061 2.39606 2.07347 2.4482C1.99633 2.50034 1.93646 2.57427 1.90149 2.66056C1.86653 2.74686 1.85806 2.84161 1.87715 2.93274C1.89625 3.02387 1.94204 3.10725 2.00871 3.17225L6.83659 8.00013L2.01481 12.8219C1.96982 12.8651 1.93391 12.9168 1.90917 12.9741C1.88442 13.0313 1.87135 13.0929 1.87072 13.1553C1.87008 13.2177 1.8819 13.2795 1.90547 13.3373C1.92905 13.395 1.9639 13.4475 2.008 13.4916C2.0521 13.5357 2.10456 13.5705 2.16229 13.5941C2.22003 13.6177 2.28189 13.6295 2.34426 13.6288C2.40662 13.6282 2.46823 13.6151 2.52548 13.5904C2.58272 13.5657 2.63446 13.5297 2.67765 13.4848L7.49943 8.66297L12.3224 13.4872C12.366 13.5308 12.4178 13.5654 12.4748 13.589C12.5317 13.6126 12.5928 13.6248 12.6545 13.6248C12.7161 13.6248 12.7772 13.6126 12.8342 13.589C12.8912 13.5654 12.9429 13.5308 12.9865 13.4872C13.0301 13.4436 13.0647 13.3919 13.0883 13.3349C13.1119 13.2779 13.1241 13.2168 13.1241 13.1552C13.1241 13.0935 13.1119 13.0324 13.0883 12.9755C13.0647 12.9185 13.0301 12.8667 12.9865 12.8231L8.16227 8.00013L12.9902 3.17225C13.0583 3.10683 13.1051 3.02233 13.1244 2.92984C13.1437 2.83736 13.1347 2.74119 13.0984 2.65396C13.0621 2.56672 13.0003 2.49249 12.9211 2.44099C12.8418 2.3895 12.7489 2.36313 12.6545 2.36537Z"
        fill="#65605A"
        stroke="#65605A"
      />
    </svg>
  );
};
