import { db } from "../lib/connect-firebase"; // Make sure the path is correct
import { getDoc, doc } from "firebase/firestore";
import { CareTakerType } from "@/app/(single)/_components/middle-section/left-section/care-taker-info";

export class CareTakerDataService {
  async getCaretaker({
    propertyId,
  }: {
    propertyId: string;
  }): Promise<null | CareTakerType> {
    try {
      // Get the document directly using the propertyId as the document ID
      const caretakerDocRef = doc(db, "caretakerInfo", propertyId);
      const caretakerDocSnap = await getDoc(caretakerDocRef);

      if (!caretakerDocSnap.exists()) {
        console.log(`No caretaker found for property ID: ${propertyId}`);
        return null;
      }

      const caretakerData = caretakerDocSnap.data();

      // Map the Firestore data to the CareTakerType
      const caretaker: CareTakerType = {
        phoneNumber: caretakerData.phoneNumber || "",
        name: caretakerData.name || "",
        imageUrl: caretakerData.imageUrl || "",
        description: caretakerData.description || "",
      };

      return caretaker;
    } catch (error) {
      console.error("Error fetching caretaker:", error);
      return null;
    }
  }
}
