import { BodyText } from "@/components/common/texts/body-text";
import { LabelText } from "@/components/common/texts/label-text";
import { TitleText } from "@/components/common/texts/title-text";
import React, { useState } from "react";
import { AboutPropertyType } from "@/types/common";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import * as gtag from "./../../../../../../lib/gtag";

export const AboutPropertyDesktop = ({
  aboutProperty,
}: {
  aboutProperty: AboutPropertyType;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
    gtag.event({
      action: `${aboutProperty.id}_read_description`,
      category: 'details_engagement',
      label: 'read description pressed',
      value: 1,
    });
  };

  return (
    <div className="flex flex-col gap-y-4 w-full mobile:px-5">
      <TitleText className="text-primaryB7 mobile:!text-[24px] mobile:!font-semibold">
        About the Property
      </TitleText>
      <div className="relative flex flex-col gap-y-5 items-start">
        <div className="flex flex-col gap-y-4">
          <Text>{aboutProperty.description}</Text>
          {isExpanded && (
            <div className="flex flex-col gap-y-4">
              {aboutProperty.pointers.map((item) => (
                <div key={item.heading} className="flex flex-col gap-y-1">
                  <Text className="underline !capitalize !text-neutralN8">
                    {item.heading}:
                  </Text>
                  {item.points.map((item) => (
                    <div key={item} className="w-full flex items-start gap-x-0 5">
                      <MdOutlineKeyboardArrowRight
                        className="mt-0.5"
                        color="#9b938a"
                        size={16}
                      />
                      <Text className="w-[90%]">{item}</Text>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
        <button className="cursor-pointer" onClick={toggleExpanded}>
          <LabelText className="text-primaryB8 mobile:!text-[14px] leading-[16px]">
            {isExpanded ? "read less" : "read more →"}
          </LabelText>
        </button>
      </div>
    </div>
  );
};

const Text = ({
  className,
  children,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <BodyText
      className={`${className} !font-normal !text-[16px] mobile:!text-[12px] text-neutralN7 !leading-[18px] mobile:!leading-[14px]`}
    >
      {children}
    </BodyText>
  );
};
