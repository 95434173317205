"use client";

import React, { Fragment, ReactNode, useEffect } from "react";
import "./photo-modal.css";
import { BodyText } from "./common/texts/body-text";
import { CloseIcon } from "../../assets/close-icon";
import { TitleText } from "./common/texts/title-text";

type ModalProps = {
  children: ReactNode;
  className?: string;
};

type OverlayProps = {
  onClose: () => void;
  overlayClassName?: string;
};

type ModalOverlayProps = {
  children: ReactNode;
  onClose: () => void;
  className?: string;
  numberOfPhotos: number;
  currentPhoto: number;
  photoCategory: string;
};

const Modal: React.FC<ModalProps> = ({ children, className }) => {
  return <div className={`photo-modal ${className}`}>{children}</div>;
};

const Overlay: React.FC<OverlayProps> = ({ onClose }) => {
  return <div className={`photo-backdrop`} onClick={onClose}></div>;
};

const CancelButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <button
      onClick={onClose}
      className="fixed z-[100000000] top-9 right-10 mobile:right-auto mobile:left-4 flex items-center justify-center gap-x-2 text-white px-4 py-2 rounded cursor-pointer"
    >
      <CloseIcon color="white" />
      <BodyText className="!text-[16px] text-neutralN2">Close</BodyText>
    </button>
  );
};

const PhotosCounter = ({
  numberOfPhotos,
  currentPhoto,
}: {
  numberOfPhotos: number;
  currentPhoto: number;
}) => {
  return (
    <BodyText className="!text-[16px] text-neutralN2 fixed !leading-0 top-10 mobile:top-11 left-1/2 mobile:left-auto mobile:right-4 py-2 -translate-x-1/2 z-[10000000000]">
      {currentPhoto}/{numberOfPhotos}
    </BodyText>
  );
};

const PhotoCategory = ({ photoCategory }: { photoCategory: string }) => {
  return (
    <BodyText className="!text-[16px] text-neutralN2 fixed !leading-0 bottom-10 mobile:bottom-11 left-1/2 py-2 -translate-x-1/2 z-[10000000000]">
      {photoCategory}
    </BodyText>
  );
};

const PhotoModalOverlay: React.FC<ModalOverlayProps> = ({
  children,
  onClose,
  className,
  currentPhoto,
  numberOfPhotos,
  photoCategory,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    const header = document.body.getElementsByTagName("header")[0];
    if (header) {
      header.style.display = "none";
    }

    return () => {
      document.body.style.overflowY = "auto";
      if (header) {
        header.style.display = "block"; // Reset the display back to its original state
      }
    };
  }, []);

  return (
    <Fragment>
      <Modal className={className}>
        <div
          className={`my-auto flex h-full w-full flex-col items-start gap-4 2xl:gap-5 overflow-y-auto`}
        >
          {children}
        </div>
      </Modal>
      <Overlay onClose={onClose} />
      <CancelButton onClose={onClose} />
      <PhotosCounter
        currentPhoto={currentPhoto}
        numberOfPhotos={numberOfPhotos}
      />
      <PhotoCategory photoCategory={photoCategory} />
    </Fragment>
  );
};

export default PhotoModalOverlay;
