import { RoomType, SelectedRoom } from "../../../../../types/room_type";
// import { ComparePrices } from "./compare-prices";
import { SelectStay } from "./select-stay";
import { AmenitiesSection } from "../../amenities-section";
import { AmenityType } from "../../../../../types/properties_type";
import { AboutPropertyDesktop } from "./about-property-desktop";
import { PropertyInfo } from "./property-info";
import { AboutPropertyType, ReviewType, SearchDataType } from "@/types/common";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Highlights } from "./highlights";
import { WhatsappLink } from "./whatsapp-link";
import { MobilePhotos } from "./mobile-photos";
import { LocationSection } from "../../location-section";
import { FAQSection } from "../../faqs-section";
import { Divider } from "./divider";
import { HouseRules } from "./house-rules";
import { CancellationPolicy } from "./cancellation-policy";
import { PropertyDetailsFirebaseImpl } from "../../../../../../data/property_data";
import { ReviewsSection } from "./reviews-section";
import { Coupons } from "./coupons";
import { CareTakerInfo } from "./care-taker-info";

interface Props {
  id: string;
  getSelectedRooms: (room: SelectedRoom[] | null) => void;
  propertyId: string;
  price: number;
  description: string;
  amenities: string[];
  allAmenities: AmenityType[];
  mainDescription: string;
  noOfBeds: number;
  noOfBedrooms: number;
  noOfGuests: number;
  noOfBathrooms: number;
  location: string;
  name: string;
  images: string[];
  bookedRooms: string[];
  bookedDates: undefined | Date[];
  searchData: SearchDataType;
  setSearchData: Dispatch<SetStateAction<SearchDataType>>;
  address: string;
  locationDescription: string;
  lat: number;
  lng: number;
  aboutProperty: AboutPropertyType;
  baseOccupancy: number;
  redirectionLink: string;
  maxOccupancy: number;
  city: string;
  rooms: RoomType[];
}

export const LeftSection = ({
  id,
  getSelectedRooms,
  amenities,
  allAmenities,
  noOfBathrooms,
  noOfBedrooms,
  noOfGuests,
  noOfBeds,
  location,
  name,
  images,
  bookedRooms,
  bookedDates,
  searchData,
  setSearchData,
  lat,
  lng,
  address,
  locationDescription,
  aboutProperty,
  baseOccupancy,
  redirectionLink,
  maxOccupancy,
  city,
  rooms,
}: Props) => {
  // const getPrice = () => {
  //   const sp = specialPricesForRooms.find((el) => el.roomId === propertyId);
  //   if (!sp) return price;

  //   return sp.specialPrice;
  // };
  const [allReviews, setAllReviews] = useState<{
    reviews: ReviewType[];
    avgRating: number;
    noOfReviews: number;
  } | null>(null);
  const propertyDataImpl = useMemo(() => new PropertyDetailsFirebaseImpl(), []);

  useEffect(() => {
    (async () => {
      const data = await propertyDataImpl.getPropertyReviews({
        propertyId: id,
      });
      if (data) {
        setAllReviews(data);
      }
    })();
  }, [id, propertyDataImpl]);

  return (
    <div
      className={`w-[65%] pr-[75px] mobile:pr-0 mobile:w-full mobile:px-0 h-auto flex flex-col gap-y-[50px] mobile:gap-y-[30px] mobile:py-5`}
    >
      <PropertyInfo
        noOfBathrooms={noOfBathrooms}
        noOfBedrooms={noOfBedrooms}
        noOfBeds={noOfBeds}
        noOfGuests={noOfGuests}
        location={location}
        name={name}
        id={id}
        avgRating={allReviews ? allReviews.avgRating : null}
        totalReviews={allReviews ? allReviews.noOfReviews : null}
      />
      <Divider />
      {searchData.checkInDate && searchData.checkOutDate ? (
        <Coupons checkInDate={searchData.checkInDate} />
      ) : null}
      <Highlights propertyId={id} />
      <Divider />
      <CareTakerInfo propertyId={id} />
      <Divider />
      <AboutPropertyDesktop aboutProperty={aboutProperty} />
      <MobilePhotos propertyName={name} images={images} />
      <WhatsappLink propertyName={name} id={id} />
      <Divider />
      {/* <ComparePrices price={getPrice()} propertyId={propertyId} /> */}
      <SelectStay
        propertyName={name}
        getSelectedRooms={getSelectedRooms}
        bookedRooms={bookedRooms}
        bookedDates={bookedDates}
        searchData={searchData}
        setSearchData={setSearchData}
        propertyId={id}
        baseOccupancy={baseOccupancy}
        maxOccupancy={maxOccupancy}
        city={city}
        rooms={rooms}
      />
      <Divider />
      <AmenitiesSection
        allAmenities={allAmenities}
        amenities={amenities}
        id={id}
      />
      <Divider />
      {/* <PlatformsSection propertyId={propertyId} />
      <Divider /> */}
      <ReviewsSection
        allReviews={allReviews ? allReviews?.reviews : null}
        avgRating={allReviews ? allReviews.avgRating : null}
      />
      <LocationSection
        id={id}
        address={address}
        name={name}
        lat={lat}
        lng={lng}
        location={location}
        locationDescription={locationDescription}
        redirectionLink={redirectionLink}
      />
      <Divider />
      <HouseRules />
      <Divider />
      <CancellationPolicy />
      <Divider />
      <FAQSection />
    </div>
  );
};
