import React from "react";
import { FaSpinner } from "react-icons/fa";

const Loading = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <FaSpinner color="#442208" size={50} className="infinite-rotate" />
    </div>
  );
};

export default Loading;
