"use client";

import { Photos } from "../photos";

interface Props {
  name: string;
  images: string[];
}

export const Hero = ({ name, images }: Props) => {
  return (
    <div className="mobile:hidden">
      <Photos
        image1={images[0]}
        image2={images[1]}
        image3={images[2]}
        propertyName={name}
      />
    </div>
  );
};
