export const MobileSearchRushDealIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3863_28962"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <rect x="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3863_28962)">
        <path
          d="M13.0007 16.6663C11.1395 16.6663 9.56315 16.0205 8.27148 14.7288C6.97982 13.4372 6.33398 11.8608 6.33398 9.99967C6.33398 8.15245 6.97982 6.57954 8.27148 5.28092C9.56315 3.98231 11.1395 3.33301 13.0007 3.33301C14.8479 3.33301 16.4208 3.98231 17.7194 5.28092C19.018 6.57954 19.6673 8.15245 19.6673 9.99967C19.6673 11.8608 19.018 13.4372 17.7194 14.7288C16.4208 16.0205 14.8479 16.6663 13.0007 16.6663ZM13.0007 14.9997C14.3895 14.9997 15.5701 14.5136 16.5423 13.5413C17.5145 12.5691 18.0006 11.3886 18.0006 9.99967C18.0006 8.61079 17.5145 7.43023 16.5423 6.45801C15.5701 5.48579 14.3895 4.99967 13.0007 4.99967C11.6118 4.99967 10.4312 5.48579 9.45898 6.45801C8.48676 7.43023 8.00065 8.61079 8.00065 9.99967C8.00065 11.3886 8.48676 12.5691 9.45898 13.5413C10.4312 14.5136 11.6118 14.9997 13.0007 14.9997ZM14.8965 13.1038L16.084 11.9163L13.834 9.66634V6.66634H12.1673V10.3538L14.8965 13.1038ZM2.16732 7.49967V5.83301H5.50065V7.49967H2.16732ZM1.33398 10.833V9.16634H5.50065V10.833H1.33398ZM2.16732 14.1663V12.4997H5.50065V14.1663H2.16732Z"
          fill="#DF3838"
        />
      </g>
    </svg>
  );
};
