export const MobileEditIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2671 1.28928C11.2032 1.28733 11.1396 1.29845 11.0802 1.32196C11.0208 1.34547 10.9668 1.38087 10.9216 1.426L1.99704 10.3506C1.9344 10.4132 1.8908 10.4924 1.87131 10.5788L1.29269 13.1472C1.27533 13.2243 1.27776 13.3046 1.29977 13.3806C1.32177 13.4566 1.36264 13.5257 1.41856 13.5816C1.47448 13.6376 1.54363 13.6784 1.61959 13.7004C1.69555 13.7224 1.77584 13.7249 1.853 13.7075L4.42136 13.1289C4.5078 13.1094 4.58695 13.0658 4.64963 13.0031L13.5742 4.07859C13.6249 4.02781 13.6632 3.96608 13.6863 3.89814C13.7094 3.83021 13.7165 3.75788 13.7072 3.68674C13.7072 3.68674 13.5986 2.8103 12.8943 2.10593C12.1899 1.40157 11.3134 1.29294 11.3134 1.29294C11.2981 1.29096 11.2826 1.28973 11.2671 1.28928ZM11.3611 2.31223C11.5016 2.34072 11.8404 2.37772 12.2314 2.76877C12.6225 3.15983 12.6595 3.49855 12.688 3.63914L11.5942 4.73289L10.2673 3.40598L11.3611 2.31223ZM9.60446 4.06882L10.9314 5.39573L4.08322 12.2439L2.37057 12.6308L2.75632 10.917L9.60446 4.06882Z"
        fill="#65605A"
        stroke="#65605A"
        strokeWidth="0.5"
      />
    </svg>
  );
};
