import { FAQ } from "./faq";

interface Props {
  faqs: {
    question: string;
    answer: string;
  }[];
}

export const FAQs = ({ faqs }: Props) => {
  return (
    <>
      {faqs.map((item) => {
        return (
          <div key={item.question} className="flex flex-col gap-y-4">
            <FAQ item={item} />
          </div>
        );
      })}
    </>
  );
};
