import Image from "next/image";
import { useEffect, useState } from "react";
import { CareTakerDataService } from "../../../../../../data/caretaker-data";
import { TitleText } from "@/components/common/texts/title-text";
import { BodyText } from "@/components/common/texts/body-text";
import { LabelText } from "@/components/common/texts/label-text";

export type CareTakerType = {
  phoneNumber: string;
  name: string;
  imageUrl: string;
  description: string;
};

export const CareTakerInfo = ({ propertyId }: { propertyId: string }) => {
  const [careTaker, setCareTaker] = useState<CareTakerType | null>(null);

  useEffect(() => {
    (async () => {
      const caretakerData = new CareTakerDataService();
      const data = await caretakerData.getCaretaker({ propertyId });
      if (!data) setCareTaker(null);
      if (data) setCareTaker(data);
    })();
  }, [propertyId]);

  if (!careTaker) return null;

  return (
    <div className="flex items-center gap-x-5 mobile:gap-x-4 mobile:px-5">
      <Image
        width={1000}
        height={1000}
        className="rounded-full w-20 h-20 object-cover mobile:w-[60px] mobile:h-[60px]"
        src={careTaker.imageUrl}
        alt={careTaker.name}
      />
      <div className="flex flex-col gap-y-2">
        <TitleText className="mobile:hidden text-primaryB7 !text-[16px] !leading-[18px]">
          Meet your caretaker
        </TitleText>
        <BodyText className="hidden mobile:block text-primaryB7 !text-[14px] !leading-[16px]">
          Meet your caretaker
        </BodyText>
        <BodyText className="mobile:hidden text-neutralN7 !text-[16px] !leading-[18px]">
          {careTaker.description}
        </BodyText>
        <LabelText className="hidden mobile:block text-neutralN7 !text-[10px] !leading-[12px]">
          {careTaker.description}
        </LabelText>
      </div>
    </div>
  );
};
