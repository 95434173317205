import { BodyText } from "@/components/common/texts/body-text";
import { DisplayText } from "@/components/common/texts/display-text";
import { HeadlineText } from "@/components/common/texts/headline-text";
import { LabelText } from "@/components/common/texts/label-text";
import { TitleText } from "@/components/common/texts/title-text";
import Link from "next/link";
import { FaStar } from "react-icons/fa";
import * as gtag from "./../../../../../../lib/gtag";

interface Props {
  noOfBeds: number;
  noOfBedrooms: number;
  noOfGuests: number;
  noOfBathrooms: number;
  location: string;
  name: string;
  totalReviews: number | null;
  avgRating: number | null;
  id: string
}

export const PropertyInfo = ({
  noOfBathrooms,
  noOfBedrooms,
  noOfBeds,
  noOfGuests,
  location,
  name,
  totalReviews,
  avgRating,
  id
}: Props) => {
  return (
    <div className="flex flex-col mobile:flex-col-reverse gap-y-7 mobile:gap-y-2.5 mobile:w-screen mobile:mr-auto mobile:px-5 mobile:py-0">
      <div className="w-full flex items-center justify-between">
        <TitleText className="text-primaryB4 text-[18px] font-bold mobile:hidden">
          {noOfBedrooms} bedrooms - {noOfBathrooms} bathrooms - {noOfBeds} beds
          - {noOfGuests} Guests
        </TitleText>
        <BodyText className="hidden mobile:block mobile:!text-[14px] mobile:!font-normal text-primaryB4">
          {noOfGuests} guests • {noOfBedrooms} Rooms • {noOfBathrooms} Baths
        </BodyText>

        {avgRating && totalReviews && (
          <RatingText avgRating={avgRating} totalReviews={totalReviews} id={id} />
        )}
      </div>
      <div>
        <DisplayText className="mobile:hidden text-primaryB7 !text-left !capitalize leading-[64px]">
          {name}
        </DisplayText>
        <HeadlineText
          className={`hidden mobile:block mobile:text-primaryB7 ${
            name === "Billionaires Glasshouse"
              ? "mobile:!text-[34px]"
              : "mobile:!text-[36px]"
          } mobile:!font-bold mobile:!capitalize`}
        >
          {name}
        </HeadlineText>
        <BodyText className="text-neutralN7 !text-left leading-[18px]">
          {location}
        </BodyText>
      </div>
    </div>
  );
};

const RatingText = ({
  totalReviews,
  avgRating,
  id
}: {
  id: string;
  totalReviews: number | null;
  avgRating: number | null;
}) => {
  const handleReviewClick = () => {
    gtag.event({
      action: `${id}_ratings_pressed`,
      category: 'engagement',
      label: 'ratings pressed',
      value: 1,
    });
  };
  return (
    <div className="mobile:absolute mobile:top-2 mobile:right-5 flex items-center gap-x-[5px]">
      <FaStar color="#65605A" size={10} />
      <LabelText className="text-neutralN7 mobile:hidden !font-normal !text-[12px]">
        {avgRating?.toFixed(1)} stars |{" "}
        <Link href="#reviews-section" className="underline" onClick={handleReviewClick}>
          {totalReviews} reviews
        </Link>
      </LabelText>
      <LabelText className="text-neutralN7 hidden mobile:block">
        {avgRating} stars |{" "}
        <Link href="#reviews-section" className="underline" onClick={handleReviewClick}>
          {totalReviews} reviews
        </Link>
      </LabelText>
    </div>
  );
};
