import { Baskervville } from "next/font/google";
import { FAQs } from "./faqs";
import { TitleText } from "@/components/common/texts/title-text";
const baskervville = Baskervville({ weight: "400", subsets: ["latin"] });
const faqs = [
  {
    question:
      "Can I entertain additional day visitors as my guests while I stay here?",
    answer:
      "Yes, additional day visitors are allowed, but extra guests outside the reservation will be chargeable.",
  },
  {
    question: "What is the general code of conduct expected from guests?",
    answer: `Respect Property: Treat belongings with care.
          Courtesy: Use polite language and manners.
          Follow Rules: Adhere to host's guidelines.
          Privacy: Respect others' personal space.
          Cleanliness: Clean up after yourself.
          Safety: Avoid unsafe behaviors.
          Communication: Keep the host informed.`,
  },
  {
    question: "How can I make the payment?",
    answer: `Online, Bank Transfer, Cash, UPI`,
  },
  {
    question: "When can I be sure my booking is confirmed?",
    answer:
      "You will receive a confirmation call from our team, or feel free to reach out by calling the number provided on our website.",
  },
  {
    question: "Are commercial activities allowed on property?",
    answer:
      "Commercial activity such as commercial shoots, commercial events etc are not allowed on standard booking. Please contact us at +918608600718 or hello@spacez.co for commercial bookings.",
  },
  {
    question: "Talk to us for any further queries",
    answer:
      "Feel free to contact us with any further questions or concerns. Our team is available around the clock, ensuring you have access to support whenever you need it.",
  },
];

export const FAQSection = () => {
  return (
    <div className="mobile:px-[20px] w-full mobile:w-full mobile:mx-auto flex flex-col gap-y-12 justify-between">
      <TitleText className="text-primaryB7 mobile:!text-[24px] mobile:!font-medium">
        frequently asked questions
      </TitleText>
      <div className="w-full mobile:w-full flex flex-col gap-y-5">
        <FAQs faqs={faqs} />
      </div>
    </div>
  );
};
