import { FaSpinner } from "react-icons/fa";
import React from "react";
import { BodyText } from "@/components/common/texts/body-text";

interface Props {
  isPending: boolean;
  text: string;
  isNotActive: boolean;
  onSubmit: () => void;
}

export const SubmitButton = ({
  isNotActive,
  isPending,
  text,
  onSubmit,
}: Props) => {
  return (
    <>
      {isNotActive ? (
        <InActiveButton onSubmit={onSubmit}>{text}</InActiveButton>
      ) : (
        <ActiveButton onSubmit={onSubmit}>
          {isPending ? (
            <FaSpinner size={20} className="infinite-rotate" />
          ) : (
            text
          )}
        </ActiveButton>
      )}
    </>
  );
};

export const InActiveButton = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit: () => void;
}) => {
  return (
    <button
      onClick={onSubmit}
      className={`cursor-default bg-neutralN4 border border-primaryB2 p-[15px] w-full h-auto flex items-center justify-center gap-x-2.5`}
    >
      <BodyText className="!text-[14px] text-primaryB2">{children}</BodyText>
    </button>
  );
};

export const ActiveButton = ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit: () => void;
}) => {
  return (
    <button
      onClick={onSubmit}
      className={`bg-primaryB4 border border-primaryB2 p-[15px] w-full h-auto flex items-center justify-center gap-x-2.5`}
    >
      <BodyText className="!text-[14px] text-neutralN3">{children}</BodyText>
    </button>
  );
};
