import { TextsPropType } from "@/types/common";

export const HeadlineText = ({ children, className }: TextsPropType) => {
  return (
    <h2
      className={`${className} font-[SandeMoreDemo] lowercase text-[36px] font-bold tracking-[0.03em] mobile:text-[28px] mobile:font-extrabold mobile:tracking-[0.02em]`}
    >
      {children}
    </h2>
  );
};
