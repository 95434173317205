import { LabelText } from "@/components/common/texts/label-text";
import Image from "next/image";
import Link from "next/link";

interface Props {
  image1: string;
  image2: string;
  image3: string;
  propertyName: string;
}

export const Photos = ({ propertyName, image1, image2, image3 }: Props) => {
  return (
    <div className="h-[500px] mobile:h-auto w-full relative">
      <div className="w-full h-full flex mobile:flex-col gap-x-2 mobile:gap-x-0 mobile:gap-y-2">
        <Link
          href="?showPhotos=true"
          className="w-[65%] mobile:w-full h-full mobile:h-[230px] overflow-hidden rounded-[5px]"
        >
          <Image
            width={1000}
            height={1000}
            src={image1}
            className="w-full h-full object-cover"
            alt={propertyName}
          />
        </Link>
        <div className="w-[35%] mobile:w-full h-full mobile:h-[150px] flex flex-col mobile:flex-row gap-y-2 mobile:gap-y-0 mobile:gap-x-2">
          <Link
            href="?showPhotos=true"
            className="w-full mobile:w-1/2 h-1/2 mobile:h-full overflow-hidden rounded-[5px]"
          >
            <Image
              width={1000}
              height={1000}
              src={image2}
              className="w-full h-full object-cover"
              alt={propertyName}
            />
          </Link>
          <Link
            href="?showPhotos=true"
            className="w-full mobile:w-1/2 h-1/2 mobile:h-full overflow-hidden rounded-[5px]"
          >
            <Image
              width={1000}
              height={1000}
              src={image3}
              className="w-full h-full object-cover"
              alt={propertyName}
            />
          </Link>
        </div>
      </div>

      <Link
        href="?showPhotos=true"
        className="block cursor-pointer rounded-[15px] absolute bottom-5 right-5 py-1 px-3 bg-primaryB8 bg-opacity-75 mobile:bottom-[40%] mobile:-translate-y-1/2 mobile:right-2"
      >
        <LabelText className="text-white mobile:!text-[12px]">
          see all photos
        </LabelText>
      </Link>
    </div>
  );
};
