export const DetailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <mask
        id="mask0_1258_6304"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1258_6304)">
        <path
          d="M9.16699 14.167H10.8337V9.16699H9.16699V14.167ZM10.0003 7.50033C10.2364 7.50033 10.4344 7.42046 10.5941 7.26074C10.7538 7.10102 10.8337 6.9031 10.8337 6.66699C10.8337 6.43088 10.7538 6.23296 10.5941 6.07324C10.4344 5.91352 10.2364 5.83366 10.0003 5.83366C9.76421 5.83366 9.5663 5.91352 9.40658 6.07324C9.24685 6.23296 9.16699 6.43088 9.16699 6.66699C9.16699 6.9031 9.24685 7.10102 9.40658 7.26074C9.5663 7.42046 9.76421 7.50033 10.0003 7.50033ZM10.0003 18.3337C8.84755 18.3337 7.76421 18.1149 6.75033 17.6774C5.73644 17.2399 4.85449 16.6462 4.10449 15.8962C3.35449 15.1462 2.76074 14.2642 2.32324 13.2503C1.88574 12.2364 1.66699 11.1531 1.66699 10.0003C1.66699 8.84755 1.88574 7.76421 2.32324 6.75033C2.76074 5.73644 3.35449 4.85449 4.10449 4.10449C4.85449 3.35449 5.73644 2.76074 6.75033 2.32324C7.76421 1.88574 8.84755 1.66699 10.0003 1.66699C11.1531 1.66699 12.2364 1.88574 13.2503 2.32324C14.2642 2.76074 15.1462 3.35449 15.8962 4.10449C16.6462 4.85449 17.2399 5.73644 17.6774 6.75033C18.1149 7.76421 18.3337 8.84755 18.3337 10.0003C18.3337 11.1531 18.1149 12.2364 17.6774 13.2503C17.2399 14.2642 16.6462 15.1462 15.8962 15.8962C15.1462 16.6462 14.2642 17.2399 13.2503 17.6774C12.2364 18.1149 11.1531 18.3337 10.0003 18.3337ZM10.0003 16.667C11.8614 16.667 13.4378 16.0212 14.7295 14.7295C16.0212 13.4378 16.667 11.8614 16.667 10.0003C16.667 8.13921 16.0212 6.56283 14.7295 5.27116C13.4378 3.97949 11.8614 3.33366 10.0003 3.33366C8.13921 3.33366 6.56283 3.97949 5.27116 5.27116C3.97949 6.56283 3.33366 8.13921 3.33366 10.0003C3.33366 11.8614 3.97949 13.4378 5.27116 14.7295C6.56283 16.0212 8.13921 16.667 10.0003 16.667Z"
          fill="#BD693D"
        />
      </g>
    </svg>
  );
};
