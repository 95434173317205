import { TextsPropType } from "@/types/common";

export const DisplayText = ({ children, className }: TextsPropType) => {
  return (
    <h1
      className={`font-[SandeMoreDemo] ${className} lowercase text-[60px] mobile:text-[39px] font-semibold mobile:font-extrabold tracking-[0.03em] mobile:tracking-[0.01em]`}
    >
      {children}
    </h1>
  );
};
