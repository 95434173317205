"use client";

import Image from "next/image";
import { RoomType, SelectedRoom } from "../../../../../../types/room_type";
import "./select-stay.css";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { GlobalDataService } from "../../../../../../../data/global_data";
import { AmenityType } from "../../../../../../types/properties_type";
import { BottomBar } from "../../../bottom-bar";
import { useLocalStorageRoomsData } from "@/hooks/use-local-storage-rooms-data";
import { TitleText } from "@/components/common/texts/title-text";
import { BodyText } from "@/components/common/texts/body-text";
import toast from "react-hot-toast";
import { SearchDataType } from "@/types/common";
import { RocketIcon } from "../../../../../../../assets/rocketIcon";
import { DetailIcon } from "../../../../../../../assets/detailIcon";
import { LabelText } from "@/components/common/texts/label-text";
import { useUpdateRooms } from "@/store/update-rooms";
import { getRoomPrice } from "@/lib/utils";
import * as gtag from "./../../../../../../../lib/gtag";
import { formatIndianCurrency } from "../../../../../../../lib/utility";
import { useUpdatePrices } from "@/store/update-prices";

interface Props {
  getSelectedRooms: (rooms: SelectedRoom[] | null) => void;
  propertyName: string;
  bookedRooms: string[];
  bookedDates: undefined | Date[];
  searchData: SearchDataType;
  setSearchData: Dispatch<SetStateAction<SearchDataType>>;
  propertyId: string;
  baseOccupancy: number;
  maxOccupancy: number;
  city: string;
  rooms: RoomType[];
}

export const SelectStay = ({
  getSelectedRooms,
  propertyName,
  bookedRooms,
  bookedDates,
  searchData,
  setSearchData,
  propertyId,
  baseOccupancy,
  maxOccupancy,
  city,
  rooms,
}: Props) => {
  const [selectedRooms, setSelectedRooms] = useState<SelectedRoom[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isDetailedClicked, setIsDetailedClicked] = useState(false);
  const detailRef = useRef<HTMLDivElement>(null);
  const detailButtonRef = useRef<HTMLButtonElement>(null);
  const fullVillaSelected = selectedRooms.find((room) => room.isVilla);
  // const { pricePerNight: propertyPricePerNight, defaultPropertyPrice } =
  //   useUpdatePropertyPrice((state) => state);
  const { villaPricePerNight, defaultPropertyPrice } = useUpdatePrices(
    (state) => state
  );
  const { setIsBooked, isBooked } = useUpdateRooms((state) => state);

  const maxGuests = fullVillaSelected ? maxOccupancy : selectedRooms.length * 2;

  const [allAmenities, setAllAmenities] = useState<AmenityType[]>([]);

  const handleSelect = ({
    i,
    defaultPrice,
    roomId,
    defaultOccupancy,
    propertyId,
    isVilla,
    roomName,
  }: {
    i: number;
    defaultPrice: number;
    roomId: string;
    defaultOccupancy: number;
    propertyId: string;
    isVilla: boolean;
    roomName: string;
  }) => {
    let nextSelectedRooms = [...selectedRooms];
    let eventName = "";

    if (isVilla) {
      eventName = `${propertyId}_villa_selected`;
      const isFullVillaSelected = selectedRooms.some((room) => room.isVilla);
      if (isFullVillaSelected) {
        // Deselect all rooms
        nextSelectedRooms = [];
      } else {
        // Select all rooms
        nextSelectedRooms = rooms.map((room, index) => ({
          index,
          price: room.defaultPrice,
          roomId: room.roomId,
          guests: room.maximumOccupancy,
          propertyId: room.propertyId,
          isVilla: room.isVilla,
          roomName: room.name,
          propertyName,
        }));
      }
    } else {
      eventName = `${propertyId}_room_selected`;
      const isAllRoomsSelected = selectedRooms.length === rooms.length;
      if (isAllRoomsSelected) {
        // Deselect all and select only this room
        nextSelectedRooms = [
          {
            index: i,
            price: defaultPrice,
            roomId,
            guests: defaultOccupancy,
            propertyId,
            isVilla,
            roomName,
          },
        ];
      } else {
        const roomAlreadySelected = selectedRooms.find(
          (room) => room.index === i
        );
        if (roomAlreadySelected) {
          // Remove the room from selectedRooms
          nextSelectedRooms = selectedRooms.filter((room) => room.index !== i);
        } else {
          // Add the room to selectedRooms
          nextSelectedRooms = [
            ...selectedRooms,
            {
              index: i,
              price: defaultPrice,
              roomId,
              guests: defaultOccupancy,
              propertyId,
              isVilla,
              roomName,
            },
          ];
        }

        // Check if all rooms (excluding "Full Villa") are selected
        const selectedRoomIds = nextSelectedRooms.map((room) => room.roomId);
        const allRoomsExceptFullVilla = rooms.filter((room) => !room.isVilla);
        const allRoomsExceptFullVillaIds = allRoomsExceptFullVilla.map(
          (room) => room.roomId
        );
        const isAllRoomsSelectedExceptFullVilla =
          allRoomsExceptFullVillaIds.every((id) =>
            selectedRoomIds.includes(id)
          );

        if (isAllRoomsSelectedExceptFullVilla) {
          // Add "Full Villa" to selectedRooms
          if (!nextSelectedRooms.some((room) => room.isVilla)) {
            const fullVillaRoom = rooms.find((room) => room.isVilla);
            if (fullVillaRoom) {
              const index = rooms.findIndex(
                (room) => room.roomId === fullVillaRoom.roomId
              );
              nextSelectedRooms.push({
                index,
                price: fullVillaRoom.defaultPrice,
                roomId: fullVillaRoom.roomId,
                guests: fullVillaRoom.maximumOccupancy,
                propertyId: fullVillaRoom.propertyId,
                isVilla: fullVillaRoom.isVilla,
                roomName: fullVillaRoom.name,
              });
            }
          }
        } else {
          // Remove "Full Villa" if not all rooms are selected
          if (nextSelectedRooms.some((room) => room.isVilla)) {
            nextSelectedRooms = nextSelectedRooms.filter(
              (room) => !room.isVilla
            );
          }
        }
      }
    }

    gtag.event({
      action: eventName,
      category: "details_engagement",
      label: "room options selected",
      value: 1,
    });
    setSelectedRooms(nextSelectedRooms);
  };

  useEffect(() => {
    (async () => {
      const globalDataService = new GlobalDataService();
      const amenities = await globalDataService.getAmenities();
      setAllAmenities(amenities);
    })();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      if (
        detailButtonRef.current &&
        !detailButtonRef.current.contains(target) &&
        detailRef.current &&
        !detailRef.current.contains(target)
      ) {
        setIsDetailedClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleListener = () => {
      window.innerWidth < 475 ? setIsMobile(true) : setIsMobile(false);
    };
    window.addEventListener("resize", handleListener);

    handleListener();

    return () => {
      window.removeEventListener("resize", handleListener);
    };
  }, []);

  useEffect(() => {
    if (selectedRooms && selectedRooms.length > 0) {
      selectedRooms.forEach((room) => {
        const isBooked = bookedRooms.some(
          (bookedRoom) => bookedRoom === room.roomId
        );
        setIsBooked(isBooked);
        if (isBooked) {
          setSelectedRooms([]);
        }
      });
    }
  }, [selectedRooms, bookedRooms, setIsBooked, searchData]);

  useEffect(() => {
    getSelectedRooms(selectedRooms);
  }, [selectedRooms, getSelectedRooms]);

  useEffect(() => {
    setSelectedRooms((prevSelectedRooms) => {
      return prevSelectedRooms.map((selectedRoom) => {
        const updatedRoom = rooms.find(
          (room) => room.roomId === selectedRoom.roomId
        );
        if (updatedRoom) {
          if (updatedRoom.defaultPrice !== selectedRoom.price) {
            return {
              ...selectedRoom,
              price: updatedRoom.defaultPrice,
            };
          }
        }
        return selectedRoom;
      });
    });
  }, [rooms]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      if (
        detailButtonRef.current &&
        !detailButtonRef.current.contains(target) &&
        detailRef.current &&
        !detailRef.current.contains(target)
      ) {
        setIsDetailedClicked(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useLocalStorageRoomsData({ rooms, setSelectedRooms, selectedRooms });

  return (
    <div
      id="select-stay"
      className="flex flex-col gap-y-6 mobile:gap-y-5 mobile:px-5"
    >
      <TitleText className="text-primaryB7 mobile:!text-[24px] mobile:!font-medium">
        Select your stay
      </TitleText>

      <div className="flex flex-col gap-y-2.5">
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-x-2.5">
            <RocketIcon color="#BD693D" />
            <BodyText className="text-primaryB4">best price promise!</BodyText>
          </div>
          <div className="relative">
            {isDetailedClicked && (
              <div
                ref={detailRef}
                className="w-[200px] mobile:w-[160px] z-30 absolute right-0 bottom-10 text-center p-2.5 border-[0.5px] border-primaryB2 rounded-[2.5px] bg-neutralN2"
              >
                <LabelText className="text-primaryB4">
                  if you find a better price elsewhere, we will refund you the
                  extra amount
                </LabelText>
              </div>
            )}
            <button
              ref={detailButtonRef}
              onClick={() => setIsDetailedClicked((prev) => !prev)}
            >
              <DetailIcon />
            </button>
          </div>
        </div>
        {rooms
          .sort((a, b) => b.defaultPrice - a.defaultPrice)
          .map(
            (
              {
                roomId,
                photos,
                name,
                defaultPrice,
                isVilla,
                propertyId,
                amenities,
                description,
                maximumOccupancy,
              },
              i
            ) => {
              const amenitiesWithIcons = amenities.map((item) => {
                //@ts-ignore
                return allAmenities[0]?.find((i) => {
                  if (i.id !== item) return;
                  return i.id === item;
                });
              });
              const isSelected = selectedRooms.some((room) => room.index === i);
              const isBooked = bookedRooms.some(
                (bookedRoom) => bookedRoom === roomId
              );

              return (
                <div
                  onClick={() => {
                    if (isBooked) {
                      toast.error(
                        "This room is already booked on selected dates"
                      );
                      return;
                    }
                    handleSelect({
                      i,
                      defaultOccupancy: maximumOccupancy,
                      defaultPrice,
                      roomId,
                      propertyId,
                      isVilla,
                      roomName: name,
                    });
                  }}
                  key={roomId}
                  className={`${isSelected ? "selected-room" : "opacity-75"} ${
                    isBooked && "!grayscale !cursor-default"
                  } cursor-pointer single-room w-full mobile:w-full border-[2px] border-[#0000001F] rounded-[5px] p-4 mobile:p-3 flex  gap-x-4 mobile:gap-x-4`}
                >
                  <div className="relative bg-red-700 w-[111px] h-[111] rounded-sm">
                    <Image
                      className="rounded-sm object-cover"
                      src={photos[0]}
                      alt={name}
                      fill
                    />
                  </div>

                  <div className="h-full mobile:py-0 w-full flex">
                    <div className="">
                      <div className="mt-2 flex flex-col gap-1.5 mobile:gap-0 justify-start">
                        <TitleText className="text-primaryB7 !text-[18px] !font-semibold">
                          {isVilla ? "Full Villa" : name}
                        </TitleText>
                        <BodyText className="text-neutralN7">
                          {description}
                        </BodyText>
                        <div className="flex items-center gap-x-2">
                          {amenitiesWithIcons
                            .filter((i) => i)
                            .map((item) => (
                              <Image
                                className="opacity-60 w-6 h-6 mobile:w-4 mobile:h-4 object-cover"
                                key={item?.id}
                                src={item?.icon ? item.icon : ""}
                                alt={item?.label ? item.label : ""}
                                width={500}
                                height={500}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className=" ml-auto ">
                      <div className="ml-auto w-full text-right">
                        <TitleText className={`text-primaryB7 inline-block`}>
                          ₹
                          {isVilla
                            ? formatIndianCurrency(villaPricePerNight)
                            : formatIndianCurrency(
                                getRoomPrice({
                                  ogRoomPrice: +defaultPrice,
                                  ogVillaPrice: defaultPropertyPrice,
                                  newVillaPrice: villaPricePerNight,
                                })
                              )}
                          <BodyText className={`text-neutralN7 inline-block`}>
                            /night
                          </BodyText>
                        </TitleText>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
      </div>

      {isMobile && (
        <BottomBar
          city={city}
          roomIds={selectedRooms.map((r) => r.roomId)}
          roomNames={selectedRooms.map((r) => r.roomName)}
          propertyId={selectedRooms[0]?.propertyId}
          maxOccupancy={maxGuests}
          propertyName={propertyName}
          bookedDates={bookedDates}
          searchData={searchData}
          setSearchData={setSearchData}
          baseOccupancy={baseOccupancy}
          id={propertyId}
          rooms={rooms}
          isFullVilla={
            selectedRooms && selectedRooms.find((room) => room.isVilla)
              ? true
              : false
          }
        />
      )}
    </div>
  );
};
