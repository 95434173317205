export const SearchPageRushDealIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3988_31320"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="30"
        height="30"
      >
        <rect width="30" height="30" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3988_31320)">
        <path
          d="M18.75 25C15.9583 25 13.5938 24.0312 11.6562 22.0938C9.71875 20.1562 8.75 17.7917 8.75 15C8.75 12.2292 9.71875 9.86979 11.6562 7.92188C13.5938 5.97396 15.9583 5 18.75 5C21.5208 5 23.8802 5.97396 25.8281 7.92188C27.776 9.86979 28.75 12.2292 28.75 15C28.75 17.7917 27.776 20.1562 25.8281 22.0938C23.8802 24.0312 21.5208 25 18.75 25ZM18.75 22.5C20.8333 22.5 22.6042 21.7708 24.0625 20.3125C25.5208 18.8542 26.25 17.0833 26.25 15C26.25 12.9167 25.5208 11.1458 24.0625 9.6875C22.6042 8.22917 20.8333 7.5 18.75 7.5C16.6667 7.5 14.8958 8.22917 13.4375 9.6875C11.9792 11.1458 11.25 12.9167 11.25 15C11.25 17.0833 11.9792 18.8542 13.4375 20.3125C14.8958 21.7708 16.6667 22.5 18.75 22.5ZM21.5938 19.6562L23.375 17.875L20 14.5V10H17.5V15.5312L21.5938 19.6562ZM2.5 11.25V8.75H7.5V11.25H2.5ZM1.25 16.25V13.75H7.5V16.25H1.25ZM2.5 21.25V18.75H7.5V21.25H2.5Z"
          fill="#DF3838"
        />
      </g>
    </svg>
  );
};
