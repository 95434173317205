import { BookingData, TagDataType } from "@/types/common";
import {
  HeroPropertyType,
  SortedProperty,
  TagEnum,
} from "@/types/properties_type";
import { RoomType } from "@/types/room_type";
import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function shuffle(
  array: HeroPropertyType[] | SortedProperty[]
): HeroPropertyType[] | SortedProperty[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // Swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function formatDateKey(date: Date): string {
  // Ensure we extract parts of the date in local time.
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

interface Props {
  ogRoomPrice: number;
  ogVillaPrice: number;
  newVillaPrice: number;
}

export const getRoomPrice = ({
  ogRoomPrice,
  ogVillaPrice,
  newVillaPrice,
}: Props): number => {
  return Number(((ogRoomPrice / ogVillaPrice) * newVillaPrice).toFixed(0));
};

export const getPrice = ({
  roomIds,
  propertyPrice,
  propertyId,
  rooms,
  defaultPrice,
}: {
  roomIds: string[];
  propertyPrice: number;
  propertyId: string;
  rooms: RoomType[];
  defaultPrice: number;
}) => {
  if (roomIds.includes(propertyId)) return propertyPrice;
  else {
    let price = 0;
    rooms.forEach((item) => {
      if (roomIds.includes(item.roomId)) {
        price += getRoomPrice({
          ogRoomPrice: item.defaultPrice,
          ogVillaPrice: +defaultPrice,
          newVillaPrice: propertyPrice,
        });
      }
    });
    return price;
  }
};

export const isBookingDataType = (booking: unknown): booking is BookingData => {
  return (
    booking !== null &&
    typeof booking === "object" &&
    "noOfPeople" in booking &&
    "isFullVilla" in booking &&
    "id" in booking
  );
};

export const getTag = ({
  tag,
}: {
  tag: TagEnum | null;
}): TagDataType | null => {
  if (!tag) return null;

  if (tag === TagEnum.GUEST_FAVORITE) {
    return {
      tagId: "guestFavorite",
      tagName: "Guest Favorite",
      tagIcon:
        "https://res.cloudinary.com/dz3tveb47/image/upload/v1731761229/award_star_4_fzzihh.png",
    };
  } else if (tag === TagEnum.TRENDING_NOW) {
    return {
      tagId: "trending",
      tagName: "Trending Now",
      tagIcon:
        "https://res.cloudinary.com/dz3tveb47/image/upload/v1731761229/local_fire_department_3_xldejw.png",
    };
  }

  return null;
};
