import { BodyText } from "@/components/common/texts/body-text";
import { LabelText } from "@/components/common/texts/label-text";
import { TitleText } from "@/components/common/texts/title-text";
import Link from "next/link";
import { useState } from "react";

export const CancellationPolicy = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="w-full mobile:px-5 flex flex-col gap-y-5 mobile:gap-y-4">
      <TitleText className="text-primaryB7 mobile:!text-[24px] mobile:!font-semibold">
        Cancellation policy
      </TitleText>
      <BodyText
        className={`text-neutralN7 ${isExpanded ? "" : "line-clamp-5"}`}
      >
        At Spacez, we understand that plans can change, and we strive to provide
        our guests with flexibility and peace of mind. To ensure a seamless and
        stress-free experience, we offer the following cancellation policy:
        <br />
        <br />
        <strong>100% Refund</strong>: Cancellations made within 48 hours of
        booking will receive a full refund, giving you the confidence to book
        without worry. For bookings made within 48 hours before check-in, a full
        refund is applicable only if canceled within 6 hours of booking.
        <br />
        <br />
        <strong>50% Refund</strong>: If the cancellation is made after the full
        refund window but within the partial refund period:
        <br />
        Bookings made under 24 hours before check-in are eligible for a 50%
        refund within 2 hours of booking.
        <br />
        <br />
        No commercial activity is allowed for standard bookings. Please contact
        us{" "}
        <Link href="tel:+918608600718" className="underline">
          +918608600718
        </Link>{" "}
        or{" "}
        <Link
          target="_blank"
          className="underline"
          href="mailto:hello@spacez.co?subject=Query Regarding Booking&body=I have a query regarding the booking!"
        >
          hello@spacez.co
        </Link>{" "}
        for commercial activity.
        <br />
        <br />
        Our policy reflects our commitment to exceptional service and
        hospitality, ensuring that even unexpected changes are managed with the
        utmost care and attention. Please don&apos;t hesitate to reach out to
        our support team for any assistance or further clarification regarding
        your booking.
      </BodyText>
      <button className="self-start cursor-pointer" onClick={toggleExpanded}>
        <LabelText className="text-primaryB8 mobile:!text-[14px] leading-[16px]">
          {isExpanded ? "read less" : "read more →"}
        </LabelText>
      </button>
    </section>
  );
};
