"use client";
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useTransition,
} from "react";
import { PropertyDetailsFirebaseImpl } from "../../../../../data/property_data";
import { Offers } from "@/components/common/offers";
import { useRouter } from "next/navigation";
import { BottomBar } from "../bottom-bar";
import { SearchDataType } from "@/types/common";
import { RoomType } from "../../../../../lib/classes/room-type-class";
import { useIsMobile } from "@/hooks/use-is-mobile";
import Loading from "@/components/loading";
import { OffersModalCloseIcon } from "../../../../../assets/offers-modal-close-icon";
import StickyBox from "react-sticky-box";
import {
  DiscountedCoupon,
  useUpdatePropertyCoupons,
} from "@/store/update-property-coupons";
import toast from "react-hot-toast";
import { getPropertyPrices } from "@/lib/prices-data";
import { format } from "date-fns";
import { useUpdatePrices } from "@/store/update-prices";
import { updateCouponPrices } from "@/lib/coupons";
import { Lexend_Deca } from "next/font/google";
import { SubmitButton } from "../bottom-bar/submit-button";
import { DesktopOffersModalCloseIcon } from "../../../../../assets/desktop-offers-modal-close-icon";
const lexendDeca = Lexend_Deca({
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  subsets: ["latin"],
});

interface Props {
  propertyId: string;
  roomIds: string[];
  maxOccupancy: number | undefined;
  roomNames: string[];
  propertyName: string;
  bookedDates: undefined | Date[];
  searchData: SearchDataType;
  setSearchData: Dispatch<SetStateAction<SearchDataType>>;
  rooms: RoomType[];
  isFullVilla: boolean;
  baseOccupancy: number;
  city: string;
  onClose?: () => void;
  defaultPropertyPrice: number;
}

export const OffersSection = ({
  roomIds,
  propertyId,
  maxOccupancy,
  roomNames,
  propertyName,
  bookedDates,
  searchData,
  setSearchData,
  isFullVilla,
  baseOccupancy,
  city,
  rooms,
  defaultPropertyPrice,
  onClose,
}: Props) => {
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useIsMobile({});
  const { push } = useRouter();
  const [coupon, setCoupon] = useState("");
  const { appliedCoupons, updateAppliedCoupons, updateDiscountedCoupons } =
    useUpdatePropertyCoupons((state) => state);
  const [isPending, startTransition] = useTransition();
  const [isScrolled, setIsScrolled] = useState(false);

  const {
    updateAllPrices,
    updateCutThroughPrice,
    updateVillaPricePerNight,
    updateCutThroughPricePerNight,
    updatePrice,
    updateTotalDiscount,
  } = useUpdatePrices((state) => state);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!searchData.checkInDate || !searchData.checkOutDate) {
      toast.error("Please add valid dates");
      return;
    }

    if (!searchData.guests.noOfTotalGuests) {
      toast.error("Please add the number of guests");
      return;
    }

    startTransition(async () => {
      try {
        const checkIn = format(searchData.checkInDate!, "yyyy-MM-dd");
        const checkOut = format(searchData.checkOutDate!, "yyyy-MM-dd");

        const uniqueAppliedCoupons = [...appliedCoupons, coupon];

        const prices = await getPropertyPrices({
          checkIn,
          checkOut,
          propertyId,
          appliedCoupons: uniqueAppliedCoupons,
        });

        if (prices) {
          updateAllPrices(prices);

          const coupons: DiscountedCoupon[] =
            prices[searchData.guests.noOfTotalGuests].applied_coupons;
          const appliedCodes = coupons.map((item) => item.code);

          if (appliedCodes.includes(coupon)) {
            toast.success(`${coupon} applied successfully`);
            updateDiscountedCoupons(coupons);
          } else {
            toast.error(`Error applying ${coupon}`);
          }

          const uniqueAppliedCodes = Array.from(
            new Set([...appliedCoupons, ...appliedCodes])
          );
          updateAppliedCoupons(uniqueAppliedCodes);

          localStorage.setItem(
            "appliedCoupons",
            JSON.stringify([...uniqueAppliedCodes])
          );

          updateCouponPrices({
            checkIn: new Date(checkIn),
            checkOut: new Date(checkOut),
            noOfGuests: searchData.guests.noOfTotalGuests,
            allPrices: prices,
            isFullVilla,
            defaultPropertyPrice,
            updateCutThroughPricePerNight,
            updateVillaPricePerNight,
            updateCutThroughPrice,
            updatePrice,
            updateTotalDiscount,
            updateDiscountedCoupons,
          });

          setCoupon("");
        }
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong");
      }
    });
  };

  useEffect(() => {
    const propertyDataService = new PropertyDetailsFirebaseImpl();
    (async () => {
      const data = await propertyDataService.getPropertyByPropertyId({
        propertyId,
      });
      if (!data) push(`/${propertyId}`);
    })();
  }, [propertyId, push]);

  //@ts-ignore
  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;

    setIsScrolled(target.scrollTop > 0);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return <Loading />;

  if (!isMobile) {
    return (
      <div
        onScroll={handleScroll}
        className="w-full h-full flex flex-col items-start gap-y-10 overflow-y-auto"
      >
        <StickyBox
          offsetTop={0}
          className="z-50 w-full flex items-center gap-x-6 bg-white px-12 py-[30px]"
          style={{
            boxShadow: isScrolled ? "0px 6px 8px 0px #A58E7433" : "",
          }}
        >
          <form onSubmit={handleSubmit} className="flex w-full gap-x-2.5">
            <input
              value={coupon}
              onChange={({ target }) => setCoupon(target.value.toUpperCase())}
              className={`${lexendDeca.className} w-[80%] border-[0.5px] border-neutralN6 rounded-[2.5px] p-2.5 placeholder:text-neutralN8 placeholder:text-[16px] placeholder:leading-[18px]`}
              placeholder="Apply coupon"
            />
            <div className="w-[20%]">
              <SubmitButton
                isNotActive={false}
                isPending={isPending}
                onSubmit={() => {}}
                text="Apply"
              />
            </div>
          </form>
          <button type="button" onClick={onClose}>
            <DesktopOffersModalCloseIcon />
          </button>{" "}
        </StickyBox>
        <div className="pb-12 px-12">
          <Offers isListingPage isProperty={true} />
        </div>
      </div>
    );
  }

  return (
    <div className="pb-40 w-full flex flex-col">
      <button onClick={onClose} className="self-end mr-5 mt-6">
        <OffersModalCloseIcon />
      </button>
      <Offers isMobileListingPage isProperty={true} />
      <BottomBar
        city={city}
        roomIds={roomIds}
        roomNames={roomNames}
        propertyId={propertyId}
        maxOccupancy={maxOccupancy}
        propertyName={propertyName}
        bookedDates={bookedDates}
        searchData={searchData}
        setSearchData={setSearchData}
        baseOccupancy={baseOccupancy}
        id={propertyId}
        rooms={rooms}
        isFullVilla={isFullVilla}
      />
    </div>
  );
};
