export const MobileSearchLongStayIcon = () => {
  return (
    <svg
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.166992 13.6663V5.33301L5.16699 0.333008L10.167 5.33301V13.6663H0.166992ZM1.83366 11.9997H4.33366V9.49967H6.00033V11.9997H8.50033V6.02051L5.16699 2.68717L1.83366 6.02051V11.9997ZM4.33366 7.83301V6.16634H6.00033V7.83301H4.33366ZM11.8337 13.6663V4.64551L7.52116 0.333008H9.87533L13.5003 3.95801V13.6663H11.8337ZM15.167 13.6663V3.27051L12.2295 0.333008H14.5837L16.8337 2.58301V13.6663H15.167Z"
        fill="#3EA920"
      />
    </svg>
  );
};
