import { BodyText } from "@/components/common/texts/body-text";
import { Location } from "./location";
import { TitleText } from "@/components/common/texts/title-text";
import Link from "next/link";
import * as gtag from "./../../../../../lib/gtag";

interface Props {
  locationDescription: string;
  location: string;
  lat: number;
  lng: number;
  name: string;
  address: string;
  redirectionLink: string;
  id: string
}

export const LocationSection = ({
  lat,
  lng,
  name,
  address,
  redirectionLink,
  id
}: Props) => {

  const handleMapClick = () => {
    gtag.event({
      action: `${id}_map_link_clicked`,
      category: "engagement",
      label: "Map is clicked",
      value: 1
    });
  }

  return (
    <section className="mx-auto w-full mobile:w-full mobile:px-[20px] mobile:mx-auto">
      <div className="flex flex-col gap-y-8">
        <TitleText className="text-primaryB7 mobile:!text-[24px] mobile:!font-medium">
          where you&apos;ll stay
        </TitleText>
        <div className="w-full h-[350px] mobile:w-full border-[2px] border-secondaryG2 rounded-[5px] mobile:h-[230px] overflow-hidden">
          <Location name={name} lat={lat} lng={lng} />
        </div>
        <BodyText className="text-neutralN7">
          address:{" "}
          <Link
            onClick={handleMapClick}
            href={redirectionLink ? redirectionLink : "#"}
            className="underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {address}
          </Link>
        </BodyText>
      </div>
    </section>
  );
};
