"use client";

import { MapPin } from "@/components/common/map-pin";
import { useIsMobile } from "@/hooks/use-is-mobile";
import { mapContainerStyle, mapOptions } from "@/lib/map-styling";
import { GoogleMap, OverlayView, useLoadScript } from "@react-google-maps/api";

export const Location = ({
  lat,
  lng,
  name,
}: {
  lat: number;
  lng: number;
  name: string;
}) => {
  const isMobile = useIsMobile({});
  const handleMapLoad = (map: google.maps.Map) => {
    map.setCenter({ lat: lat - (isMobile ? 0.009 : 0.004), lng });
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAyVDGCUOBDxJWQWHcvonFTXAi_gwQevk8",
  });

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={15}
      options={mapOptions}
      onLoad={handleMapLoad}
    >
      <OverlayView
        key={name}
        position={{ lat, lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -width / 2,
          y: -height,
        })}
      >
        <MapPin />
      </OverlayView>
    </GoogleMap>
  );
};
