import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore"; // Firestore Client SDK methods
import { db } from "../lib/connect-firebase"; // Ensure this points to Firestore Client SDK instance
import {
  AmenityType,
  HeroPropertyType,
  Property,
} from "@/types/properties_type";
import { HighlightType, ReviewType } from "@/types/common";

interface Listing {
  id: string;
  price: number;
  listingImage: string;
  mainImage: string;
  location: string;
  name: string;
}

interface SitemapProperty {
  city: string;
  areaName: string;
  id: string;
}

export class PropertyDetailsFirebaseImpl {
  private _globalDataCollection = collection(db, "globalData");
  private _globalDataDocId = "globalDocId"; // Replace with your actual document ID
  private _propertiesCollection = collection(db, "properties");

  /**
   * Fetch all property documents from Firestore.
   * @returns A promise resolving to an array of property data or an empty array.
   */
  private async getProperties(): Promise<DocumentData[]> {
    try {
      const querySnapshot = await getDocs(this._propertiesCollection);

      if (!querySnapshot.empty) {
        const properties: DocumentData[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        return properties;
      } else {
        return [];
      }
    } catch (error) {
      console.error(`Error fetching properties: ${error}`);
      return [];
    }
  }

  /**
   * Fetch all properties and return them as a list of Property objects.
   * @returns A promise resolving to an array of Property data or an empty array.
   */
  async getAllProperties(): Promise<Property[]> {
    try {
      // Fetch raw property data using getProperties
      const rawProperties = await this.getProperties();

      // Map raw properties to Property objects
      const properties: Property[] = rawProperties.map((item) => ({
        id: item.id,
        propertyId: item.propertyId || "",
        price: item.price || 0,
        listingImage: item.listingImage || "",
        mainImage: item.mainImage || "",
        location: item.location || "",
        name: item.name || "",
        type: item.type || "",
        noOfBedrooms: item.noOfBedrooms || 0,
        noOfBathrooms: item.noOfBathrooms || 0,
        noOfGuests: item.noOfGuests || 0,
        mainDescription: item.mainDescription || "",
        city: item.city || "",
        address: item.address || "",
        photos: item.photos || {},
        commonImages: item.commonImages || [],
        areaName: item.areaName || "",
        lat: item.lat || 0,
        lng: item.lng || 0,
        aboutProperty: item.aboutProperty,
        baseOccupancy: item.baseOccupancy,
        redirectionLink: item.redirectionLink,
        defaultOccupancy: item.defaultOccupancy,
        maxOccupancy: item.maxOccupancy,
        tag: item.tag,
        avgRating: item.avgRating,
        ...item,
      }));

      return properties;
    } catch (error) {
      console.error(`Error fetching all properties: ${error}`);
      return [];
    }
  }

  /**
   * Fetch a single property using a Firestore query where the ID matches.
   * @param id - The ID of the property to fetch.
   * @returns A promise resolving to the property object or null if not found.
   */
  private async getPropertyById(id: string): Promise<Property | null> {
    try {
      // Reference the "properties" collection
      const propertiesCollection = collection(db, "properties");

      // Create a Firestore query where "id" equals the provided id
      const q = query(propertiesCollection, where("id", "==", id));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if a document was found
      if (!querySnapshot.empty) {
        // Return the first matching document (assuming "id" is unique)
        const doc = querySnapshot.docs[0];
        return { id: doc.id, ...doc.data() } as Property;
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error fetching property with ID ${id}: ${error}`);
      return null;
    }
  }

  private async getAPropertyByPropertyId(id: string): Promise<Property | null> {
    try {
      // Reference the "properties" collection
      const propertiesCollection = collection(db, "properties");

      // Create a Firestore query where "propertyId" equals the provided id
      const q = query(propertiesCollection, where("id", "==", id));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // Check if a document was found
      if (!querySnapshot.empty) {
        // Return the first matching document (assuming "id" is unique)
        const doc = querySnapshot.docs[0];
        return { id: doc.id, ...doc.data() } as Property;
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error fetching property with ID ${id}: ${error}`);
      return null;
    }
  }
  // Helper method to fetch global data document
  private async getGlobalDataDoc(): Promise<DocumentData | null> {
    try {
      const docRef = doc(this._globalDataCollection, this._globalDataDocId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error fetching global data document: ${error}`);
      return null;
    }
  }

  async getAllSiteMapPropertyDetails(): Promise<SitemapProperty[]> {
    try {
      // Fetch properties using getProperties()
      const fetchedProperties = await this.getProperties();

      // Ensure properties is an array
      if (!fetchedProperties || !Array.isArray(fetchedProperties)) {
        return [];
      }

      // Map the properties from globalData to ensure they conform to SitemapProperty
      const properties: SitemapProperty[] = fetchedProperties.map(
        (property: any) => ({
          id: property.id || "",
          city: property.city || "",
          areaName: property.areaName || "",
        })
      );

      return properties;
    } catch (error) {
      console.error(`Error fetching sitemap Property: ${error}`);
      return [];
    }
  }

  // Fetch all listings
  async getListings(): Promise<Listing[]> {
    try {
      // Fetch properties using getProperties()
      const properties = await this.getProperties();

      // Ensure properties is an array
      if (!properties || !Array.isArray(properties)) {
        return [];
      }

      // Map properties to create a list of listings
      const listingsData: Listing[] = properties.map((item) => ({
        id: item.id,
        price: item.price,
        listingImage: item.listingImage,
        mainImage: item.mainImage,
        location: item.location,
        name: item.name,
      }));

      // Revalidate the "/" route (Next.js ISR)
      return listingsData;
    } catch (error) {
      console.error(`Error fetching listings: ${error}`);
      return [];
    }
  }

  // Fetch a single property along with amenities
  async getSingleProperty({
    id,
  }: {
    id: string;
  }): Promise<{ property: Property; amenities: AmenityType[] } | null> {
    try {
      const globalData = await this.getGlobalDataDoc();
      if (!globalData) return null;
      const fetchedProperty = await this.getPropertyById(id);
      if (!fetchedProperty) return null;
      const property: Property = fetchedProperty || [];
      const amenities: AmenityType[] = globalData.amenities || [];

      if (!property) {
        return null;
      }

      return { property, amenities };
    } catch (error) {
      console.error(`Error fetching property with ID ${id}: ${error}`);
      return null;
    }
  }

  // Check if a single property exists
  async isSingleProperty({
    id,
  }: {
    id: string;
  }): Promise<{ propertyName: string } | null> {
    try {
      const property = await this.getPropertyById(id);

      if (!property) {
        return null;
      }

      return { propertyName: property.name };
    } catch (error) {
      console.error(`Error checking property with ID ${id}: ${error}`);
      return null;
    }
  }

  // Fetch related properties based on city and property ID
  async getRelatedProperties({
    id,
    city,
  }: {
    id: string;
    city: string;
  }): Promise<{ relatedProperties: HeroPropertyType[] }> {
    try {
      const properties: Property[] = await this.getAllProperties();

      let relatedProperties: HeroPropertyType[] = [];
      const property = properties.find((item) => item.id === id);

      // First, find properties in the same city
      const sameCityProperties = properties.filter(
        (item) => item.city === city && item.id !== id
      );

      for (const item of sameCityProperties) {
        if (relatedProperties.length >= 5) break;
        relatedProperties.push({
          type: item.type,
          area: item.areaName,
          propertyId: item.id,
          price: item.price,
          images: item.commonImages,
          location: item.location,
          name: item.name,
          city: item.city,
          noOfBathrooms: item.noOfBathrooms,
          noOfBedrooms: item.noOfBedrooms,
          noOfGuests: item.noOfGuests,
          id: item.propertyId,
          baseOccupancy: item.baseOccupancy,
          defaultOccupancy: item.defaultOccupancy,
          tag: item.tag,
          avgRating: item.avgRating,
        });
      }

      // If less than 5, add properties from other cities
      if (relatedProperties.length < 5) {
        const otherProperties = properties.filter(
          (item) => item.city !== city && item.id !== id
        );
        for (const item of otherProperties) {
          if (relatedProperties.length >= 5) break;
          relatedProperties.push({
            type: item.type,
            area: item.areaName,
            propertyId: item.id,
            price: item.price,
            images: item.commonImages,
            location: item.location,
            name: item.name,
            city: item.city,
            noOfBathrooms: item.noOfBathrooms,
            noOfBedrooms: item.noOfBedrooms,
            noOfGuests: item.noOfGuests,
            id: item.propertyId,
            baseOccupancy: item.baseOccupancy,
            defaultOccupancy: item.defaultOccupancy,
            tag: item.tag,
            avgRating: item.avgRating,
          });
        }
      }

      return { relatedProperties: relatedProperties.slice(0, 5) };
    } catch (error) {
      console.error(`Error fetching related properties: ${error}`);
      return { relatedProperties: [] };
    }
  }

  // Fetch data for hero properties
  async getHeroPropertiesData(): Promise<{
    propertiesData: HeroPropertyType[];
  }> {
    try {
      const globalData = await this.getGlobalDataDoc();
      if (!globalData) return { propertiesData: [] };

      const properties: Property[] = await this.getAllProperties();
      let propertiesData: HeroPropertyType[] = [];

      properties.forEach((item) => {
        propertiesData.push({
          propertyId: item.id,
          type: item.type,
          location: item.location,
          price: item.price,
          name: item.name,
          noOfBedrooms: item.noOfBedrooms,
          noOfGuests: item.noOfGuests,
          noOfBathrooms: item.noOfBathrooms,
          images: item.commonImages,
          city: item.city,
          id: item.propertyId,
          area: item.areaName,
          baseOccupancy: item.baseOccupancy,
          defaultOccupancy: item.defaultOccupancy,
          tag: item.tag,
          avgRating: item.avgRating,
        });
      });

      return { propertiesData };
    } catch (error) {
      console.error(`Error fetching hero properties data: ${error}`);
      return { propertiesData: [] };
    }
  }

  async getPropertiesBasedOnLocation({
    city,
    area,
  }: {
    city?: string;
    area?: string;
  }): Promise<HeroPropertyType[] | null> {
    try {
      const globalData = await this.getGlobalDataDoc();
      if (!globalData) return null;

      const properties: Property[] = await this.getAllProperties();
      let propertiesData: HeroPropertyType[] = [];

      properties.forEach((item) => {
        propertiesData.push({
          propertyId: item.id,
          type: item.type,
          location: item.location,
          price: item.price,
          name: item.name,
          noOfBedrooms: item.noOfBedrooms,
          noOfGuests: item.noOfGuests,
          noOfBathrooms: item.noOfBathrooms,
          images: item.commonImages,
          city: item.city,
          id: item.propertyId,
          area: item.areaName,
          baseOccupancy: item.baseOccupancy,
          defaultOccupancy: item.defaultOccupancy,
          tag: item.tag,
          avgRating: item.avgRating,
        });
      });

      if (city)
        propertiesData = propertiesData.filter(
          (item) => item.city.toLowerCase() === city.toLowerCase()
        );

      if (city && area)
        propertiesData = propertiesData.filter(
          (item) =>
            item.city.toLowerCase() === city.toLowerCase() &&
            item.area.toLowerCase() === area.toLowerCase()
        );

      return propertiesData.length == 0 ? null : propertiesData;
    } catch (error) {
      return null;
    }
  }

  // Fetch property locations
  async getPropertyLocations(): Promise<any[]> {
    try {
      const properties: Property[] = await this.getAllProperties();
      const locations: any[] = [];

      properties.forEach((item, index) => {
        locations.push({
          key: item.name,
          location: {
            lat: item.lat,
            lng: item.lng,
          },
          id: item.id,
          price: item.price,
        });
      });

      return locations;
    } catch (error) {
      console.error(`Error fetching property locations: ${error}`);
      return [];
    }
  }

  // Fetch property by property ID
  async getPropertyByPropertyId({
    propertyId,
  }: {
    propertyId: string;
  }): Promise<{
    propertyImage: string;
    propertyName: string;
    commonImages: string[];
  } | null> {
    try {
      const property = await this.getAPropertyByPropertyId(propertyId);

      if (!property) {
        return null;
      }

      return {
        propertyImage: property.listingImage,
        propertyName: property.name,
        commonImages: property.commonImages,
      };
    } catch (error) {
      console.error(`Error fetching property by propertyId: ${error}`);
      return null;
    }
  }

  async getSinglePropertyById({ propertyId }: { propertyId: string }): Promise<{
    id: string;
    propertyImage: string;
    propertyName: string;
    commonImages: string[];
    areaName: string;
    city: string;
  } | null> {
    try {
      const propertiesCollection = collection(db, "properties");

      const q = query(
        propertiesCollection,
        where("propertyId", "==", propertyId)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        return {
          id: doc.data().id,
          propertyImage: doc.data().listingImage,
          propertyName: doc.data().name,
          commonImages: doc.data().commonImages,
          city: doc.data().city,
          areaName: doc.data().areaName,
        };
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  async getPropertyImageLocationName({
    propertyId,
  }: {
    propertyId: string;
  }): Promise<{
    city: string;
    propertyImage: string;
    propertyName: string;
    propertyPlaceId: string;
  } | null> {
    const property = await this.getAPropertyByPropertyId(propertyId);

    if (!property) {
      return null;
    }

    return {
      propertyImage: property.listingImage,
      city: property.city,
      propertyName: property.name,
      propertyPlaceId: property.placeId,
    };
  }

  // Fetch property photos
  async getPropertyPhotos({
    propertyId,
  }: {
    propertyId: string;
  }): Promise<{ photos: Record<string, string[]> } | null> {
    try {
      const property = await this.getAPropertyByPropertyId(propertyId);
      if (!property) {
        return null;
      }

      return {
        photos: property.photos || {
          something: ["something"],
          yesSomething: ["YesSomething"],
        },
      };
    } catch (error) {
      console.error(
        `Error fetching property photos for propertyId ${propertyId}: ${error}`
      );
      return null;
    }
  }

  async getPropertyHighlights({
    propertyId,
  }: {
    propertyId: string;
  }): Promise<HighlightType[] | null> {
    try {
      // Use the known document ID to get the specific document
      const docRef = doc(db, "propertyhighlights", "R2htooQVLt7mBOjCYWlh");

      // Get the document snapshot
      const docSnap = await getDoc(docRef);

      // Check if the document exists
      if (!docSnap.exists()) {
        return null;
      }

      // Extract and return the highlights data for the specific propertyId
      const data = docSnap.data();
      const highlights: HighlightType[] = data[propertyId] || [];

      if (highlights.length === 0) {
        return null;
      }

      return highlights;
    } catch (error) {
      console.error(
        `Error fetching property highlights for propertyId ${propertyId}: ${error}`
      );
      return null;
    }
  }

  async getHouseRules(): Promise<
    | {
        icon: string;
        title: string;
        description: string;
      }[]
    | null
  > {
    try {
      const docRef = doc(db, "propertyHouseRules", "D1XDc8T6QMTX8SgtS06m");
      const docSnap = await getDoc(docRef);

      // Check if the document exists
      if (!docSnap.exists()) {
        return null;
      }

      const data = docSnap.data();

      if (data.length === 0) {
        return null;
      }

      const houseRulesData = Object.values(data).map((r: any) => ({
        icon: r.icon,
        title: r.title,
        description: r.description,
      }));

      return houseRulesData;
    } catch (error) {
      return null;
    }
  }

  async getPropertyReviews({ propertyId }: { propertyId: string }): Promise<{
    reviews: ReviewType[];
    avgRating: number;
    noOfReviews: number;
  } | null> {
    try {
      const docRef = doc(db, "propertyReviews", propertyId);
      const docSnap = await getDoc(docRef);

      // Check if the document exists
      if (!docSnap.exists()) {
        return null;
      }

      const data = docSnap.data();

      if (data.length === 0) {
        return null;
      }

      const reviews: ReviewType[] = Object.values(data).map(
        ({
          rating,
          reply,
          reviewDate,
          reviewDescription,
          reviewerImage,
          reviewerName,
          reviewPlatform,
        }: ReviewType) => ({
          rating,
          reply,
          reviewDate,
          reviewDescription,
          reviewerImage,
          reviewerName,
          reviewPlatform,
        })
      );

      const avgRating = Number(
        (
          reviews.reduce((sum, review) => sum + review.rating, 0) /
          reviews.length
        ).toFixed(1)
      );

      return { reviews, avgRating, noOfReviews: reviews.length };
    } catch (error) {
      return null;
    }
  }

  async getPropertiesAvgRating(): Promise<{
    [propertyId: string]: { avgRating: number };
  } | null> {
    try {
      const collectionRef = collection(db, "propertyReviews");
      const querySnapshot = await getDocs(collectionRef);
      const results: { [propertyId: string]: { avgRating: number } } = {};

      querySnapshot.forEach((docSnap) => {
        const propertyId = docSnap.id;
        const data = docSnap.data();

        if (!data || Object.keys(data).length === 0) {
          // Skip if there's no data
          return;
        }

        const reviews: ReviewType[] = Object.values(data).map(
          (item: ReviewType) => item
        );

        const avgRating =
          reviews.length > 0
            ? Number(
                (
                  reviews.reduce((sum, review) => sum + review.rating, 0) /
                  reviews.length
                ).toFixed(1)
              )
            : 0;

        results[propertyId] = { avgRating };
      });

      return results;
    } catch (error) {
      console.error("Error fetching properties' average ratings:", error);
      return null;
    }
  }

  async getDryDiscountDays({
    propertyId,
  }: {
    propertyId: string;
  }): Promise<Date[] | null> {
    try {
      const docRef = doc(db, "dryDiscountDays", propertyId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return null;

      const data = docSnap.data();

      if (data.length === 0) return null;
      // Parse and log each date

      // Return parsed Date objects
      return data.dates.map((d: string) => {
        const [day, month, year] = d.split("-").map((num) => parseInt(num, 10));
        return new Date(year, month - 1, day);
      });
    } catch (error) {
      return null;
    }
  }
}
