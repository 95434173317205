import { COUPON_TYPES, SearchDataType } from "@/types/common";
import { create } from "zustand";

interface UpdateSearchPageCoupons {
  coupons: null | COUPON_TYPES[];
  updateCoupons: (coupons: null | COUPON_TYPES[]) => void;
}

export const useUpdateSearchPageCoupons =
  create<UpdateSearchPageCoupons>((set) => {
    return {
      coupons: null,
      updateCoupons: (coupons) =>
        set(() => ({
          coupons,
        })),
    };
  });
