import { AboutPropertyType } from "./common";

export type Property = {
  id: string;
  propertyId: string;
  price: number;
  listingImage: string;
  mainImage: string;
  location: string;
  name: string;
  type: string;
  noOfBedrooms: number;
  noOfBathrooms: number;
  noOfGuests: number;
  defaultOccupancy: number;
  maxOccupancy: number;
  mainDescription: string;
  aboutProperty: AboutPropertyType;
  city: string;
  address: string;
  photos: Record<string, string[]>;
  commonImages: string[];
  areaName: string;
  lat: number;
  lng: number;
  [key: string]: any;
  redirectionLink: string;
  tag: TagEnum | null;
  avgRating: number | null;
};

export type ListingType = {
  id: number;
  location: string;
  price: string;
  mainImage: string;
  listingImage?: string;
  name: string;
};

export type AmenityType = {
  id: string;
  label: string;
  icon: string;
};

export type HeroPropertyType = {
  id: string;
  propertyId: string;
  type: string;
  name: string;
  images: string[];
  price: number;
  location: string;
  noOfBedrooms: number;
  noOfBathrooms: number;
  noOfGuests: number;
  city: string;
  area: string;
  baseOccupancy: number;
  defaultOccupancy: number;
  total_price?: number;
  discounted_price?: number;
  discount_percentage?: number;
  avgRating: number | null;
  tag: TagEnum | null;
};

export type SortedProperty = HeroPropertyType & {
  avgRating: number | null;
  isAvailable: boolean;
};

export enum TagEnum {
  GUEST_FAVORITE = "GUEST_FAVOURITE",
  TRENDING_NOW = "TRENDING",
}
