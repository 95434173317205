import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { AddGuests } from "@/components/user-data-inputs/add-guests";
import { SelectedRoom } from "../../../../../types/room_type";
import { useRouter, useSearchParams } from "next/navigation";
import toast from "react-hot-toast";
import { COUPON_TYPES, SearchDataType } from "@/types/common";
import { addSearchDataInLocalStorage } from "@/lib/search-data";
import useLocalStorageSearchData from "@/hooks/use-local-storage-search-data";
import { BodyText } from "@/components/common/texts/body-text";
import { TitleText } from "@/components/common/texts/title-text";
import { Button } from "@/components/button";
import { DesktopDateSelector } from "@/components/user-data-inputs/date-selector";
import { checkLogin } from "@/lib/check-login";
import { useUpdateRooms } from "@/store/update-rooms";
import * as gtag from "./../../../../../../lib/gtag";
import { formatIndianCurrency } from "../../../../../../lib/utility";
import { LongStayOffer } from "../../long-stay-offer";
import { ListingPageArrowUpIcon } from "../../../../../../assets/listing-page-arrow-up-icon";
import { ListingPageArrowDownIcon } from "../../../../../../assets/listing-page-arrow-down-icon";
import {
  CostBreakDown,
  DisplayPrices,
  DisplayTotalPrices,
  ExploreNow,
  FullyBooked,
  Offer,
} from "../../common";
import { InputText } from "@/components/user-data-inputs/booking-summary-texts";
import { useUpdatePrices } from "@/store/update-prices";
import { useUpdatePropertyCoupons } from "@/store/update-property-coupons";

type Guests = {
  noOfAdults: number;
  noOfChildren: number;
  noOfPets: number;
  noOfTotalGuests: number;
};

interface Props {
  selectedRooms: SelectedRoom[] | null;
  propertyName: string;
  propertyId: string;
  searchData: SearchDataType;
  setSearchData: Dispatch<SetStateAction<SearchDataType>>;
  bookedDates: undefined | Date[];
  id: string;
  baseOccupancy: number;
  maxOccupancy: number;
  city: string;
}

export const BookingSummary = ({
  selectedRooms,
  propertyName,
  searchData,
  setSearchData,
  bookedDates,
  id,
  baseOccupancy,
  maxOccupancy,
  city,
}: Props) => {
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const [dateDifference, setDateDifference] = useState(1);
  const { isBooked: isVillaBooked } = useUpdateRooms((state) => state);
  const [isBreakdownOpen, setIsBreakdownOpen] = useState(false);
  const [isPending, startTransition] = useTransition();
  const isBooked = useSearchParams().get("booked");
  const router = useRouter();
  const {
    price: { pricePerNight, finalPrice },
    defaultPropertyPrice,
    totalDiscount,
    cutThroughPrice,
    cutThroughPricePerNight,
  } = useUpdatePrices((state) => state);
  const { validPropertyCoupons, discountedCoupons } = useUpdatePropertyCoupons(
    (state) => state
  );

  const coupon =
    validPropertyCoupons.find((item) => item.type === COUPON_TYPES.LONG_STAY) ||
    validPropertyCoupons.find((item) => item.type === COUPON_TYPES.BASIC);

  const fullVillaSelected =
    selectedRooms && selectedRooms.find((room) => room.isVilla);

  const selectedRoomIds = useMemo(
    () => (selectedRooms ? selectedRooms.map((r) => r.roomId) : []),
    [selectedRooms]
  );

  const roomNames = useMemo(
    () => (selectedRooms ? selectedRooms.map((r) => r.roomName) : []),
    [selectedRooms]
  );

  const maxGuests = fullVillaSelected
    ? maxOccupancy
    : selectedRooms
    ? selectedRoomIds.length * 2
    : 0;

  const handleDates = (date: [null | Date, null | Date]) => {
    const [startDate, endDate] = date;
    setSearchData((prev) => {
      return { ...prev, checkInDate: startDate, checkOutDate: endDate };
    });
    // Only close the modal and open guests selection when both dates are selected
    if (startDate && endDate) {
      setIsDatesModalOpen(false);
      setIsGuestsOpen(true);
    }
  };

  const handleGuests = useCallback(
    (guests: Guests) => {
      gtag.event({
        action: `guest_number_change_half`,
        category: "user input",
        label: "User trying to change guest number",
        value: 1,
      });
      setSearchData((prev) => {
        return { ...prev, guests: { ...guests } };
      });
    },
    [setSearchData]
  );

  const handleCheckAvail = (event: FormEvent) => {
    event.preventDefault();
    setIsDatesModalOpen(true);
  };

  const isDates = useMemo(
    () => searchData.checkInDate && searchData.checkOutDate,
    [searchData.checkInDate, searchData.checkOutDate]
  );

  const handleBook = async (event: FormEvent) => {
    event.preventDefault();
    if (isPending) return;
    startTransition(async () => {
      if (selectedRooms && selectedRooms.length > 0) {
        if (
          !searchData.guests.noOfTotalGuests &&
          (!searchData.checkInDate || !searchData.checkOutDate)
        ) {
          toast.error("Please select date and number of guest");
          return;
        }

        if (searchData.guests.noOfTotalGuests > maxGuests) {
          toast.error("You exceeded the maximum guests limit!");
          return;
        }

        if (!searchData.checkInDate || !searchData.checkOutDate) {
          toast.error("Please select your travel dates");
          return;
        }

        if (!searchData.guests.noOfTotalGuests) {
          toast.error("Please select the number of guests");
          return;
        }

        addSearchDataInLocalStorage(searchData);

        const now = new Date();

        const expiryTime = now.getTime() + 10 * 60 * 60 * 1000;

        const bookingData = {
          bookedRooms: selectedRoomIds,
          //@ts-ignore
          propertyId: selectedRooms[0]?.propertyId,
          noOfPeople: searchData.guests.noOfTotalGuests,
          noOfChildren: searchData.guests.noOfChildren,
          noOfPets: searchData.guests.noOfPets,
          noOfAdults: searchData.guests.noOfAdults,
          noOfDays: dateDifference,
          checkInDate: searchData.checkInDate.toDateString(),
          checkOutDate: searchData.checkOutDate.toDateString(),
          expiry: expiryTime,
          price: pricePerNight,
          id,
          roomNames,
          propertyName,
          isFullVilla: fullVillaSelected ? true : false,
          maxGuests,
          defaultPropertyPrice,
          baseOccupancy,
        };

        localStorage.removeItem("bookingData");
        localStorage.setItem("bookingData", JSON.stringify(bookingData));
        const isLoggedIn = await checkLogin();
        if (!isLoggedIn) {
          router.push("/login?booking=true");
        } else {
          router.push("/booking");
        }
      } else {
      }
    });
  };

  const exploreNow = () => {
    router.push(`/listings?city=${city}`);
    addSearchDataInLocalStorage({ ...searchData, city });
  };

  useEffect(() => {
    if (searchData.checkInDate && searchData.checkOutDate) {
      const msPerDay = 24 * 60 * 60 * 1000;

      const dateDifference =
        //@ts-ignore
        (new Date(searchData.checkOutDate) - new Date(searchData.checkInDate)) /
        msPerDay;
      setDateDifference(dateDifference);
    }
  }, [searchData.checkInDate, searchData.checkOutDate]);

  useEffect(() => {
    if (isBooked) {
      setSearchData((prev) => ({
        ...prev,
        checkInDate: null,
        checkOutDate: null,
      }));
    }
  }, [isBooked, setSearchData]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) setIsBreakdownOpen(false);
    };

    if (isBreakdownOpen) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isBreakdownOpen, setIsBreakdownOpen]);

  useLocalStorageSearchData({
    setSearchData,
    maxOccupancy,
  });

  return (
    <div
      style={{
        width: "100%",
      }}
      className="relative mobile:hidden h-auto border border-primaryB7 bg-neutralN2 rounded-[5px] p-[30px] flex flex-col gap-y-5"
    >
      {(!isDates || isVillaBooked) && (
        <div className="space-x-[5px]">
          {defaultPropertyPrice > pricePerNight && (
            <BodyText className="text-error line-through inline !leading-[16px]">
              ₹ {formatIndianCurrency(defaultPropertyPrice)}
            </BodyText>
          )}
          <TitleText className="text-primaryB9 inline !leading-[24px]">
            ₹ {formatIndianCurrency(pricePerNight)}
            <BodyText className="text-neutralN7 inline !leading-[16px]">
              /night
            </BodyText>
          </TitleText>
        </div>
      )}

      {(!isDates || isVillaBooked) && (
        <form onSubmit={handleCheckAvail}>
          <Button
            text="Check Availability"
            isNotActive={false}
            isPending={false}
          />
        </form>
      )}

      <div className="booking-summary relative flex flex-col gap-y-[5px]">
        {!isDates ? (
          <Offer isDates={false} />
        ) : isVillaBooked ? (
          <FullyBooked isModal={false} />
        ) : null}
        <div className="w-full relative flex rounded-[2.5px] gap-x-[5px]">
          <DesktopDateSelector
            isBookingSummary
            isCheckIn
            checkInDate={searchData.checkInDate}
            checkOutDate={searchData.checkOutDate}
            handleDates={handleDates}
            bookedDays={bookedDates}
            isDatesOpen={isDatesModalOpen}
            setIsDatesOpen={setIsDatesModalOpen}
          >
            <InputText text="Check in" />
          </DesktopDateSelector>

          <DesktopDateSelector
            isBookingSummary
            isCheckIn={false}
            checkInDate={searchData.checkInDate}
            checkOutDate={searchData.checkOutDate}
            handleDates={handleDates}
            bookedDays={bookedDates}
            isDatesOpen={isDatesModalOpen}
            setIsDatesOpen={setIsDatesModalOpen}
          >
            <InputText text="Check out" />
          </DesktopDateSelector>
        </div>

        <AddGuests
          bookingSummary
          guests={searchData.guests}
          handleGuests={handleGuests}
          maxGuests={maxGuests ? maxGuests : maxOccupancy}
          isGuestsOpen={isGuestsOpen}
          setIsGuestsOpen={setIsGuestsOpen}
        >
          <InputText text="number of guests" />
        </AddGuests>
      </div>

      {isVillaBooked && <ExploreNow exploreNow={exploreNow} />}

      {isDates && !isVillaBooked && (
        <>
          <div className="w-full flex flex-col gap-y-[5px]">
            <Offer isDates />
            <form onSubmit={handleBook}>
              <Button
                text="Book now"
                isNotActive={false}
                isPending={isPending}
              />
            </form>
          </div>

          <LongStayOffer
            couponName={coupon?.code}
            description={coupon?.description}
            couponType={coupon?.type}
          />

          {isBreakdownOpen ? (
            <div className="w-full flex flex-col gap-y-5">
              <CostBreakDown
                title="Stay Cost"
                breakdown={`₹ ${formatIndianCurrency(
                  cutThroughPricePerNight || pricePerNight
                )} x ${dateDifference}`}
                totalCost={`₹ ${formatIndianCurrency(
                  (cutThroughPricePerNight || pricePerNight) * dateDifference
                )}`}
                isDiscount={false}
              />

              {discountedCoupons &&
              fullVillaSelected &&
              totalDiscount &&
              discountedCoupons.length > 0
                ? discountedCoupons.map((item, index) => (
                    <CostBreakDown
                      key={item.code + index}
                      title={`${item.code} Offer`}
                      breakdown={`₹ ${formatIndianCurrency(
                        +item.discount.toFixed(0)
                      )}`}
                      totalCost={`₹ ${formatIndianCurrency(
                        +item.discount.toFixed(0)
                      )}`}
                      isDiscount
                    />
                  ))
                : null}

              <CostBreakDown
                title="Spacez Service Fee"
                breakdown={`₹ ${formatIndianCurrency(0)}`}
                totalCost={`₹ ${formatIndianCurrency(0)}`}
                isDiscount={false}
              />
            </div>
          ) : null}

          <div className="border border-neutralN5" />

          <div className="w-full flex justify-between">
            <DisplayTotalPrices />
            <div className="flex items-center gap-x-5">
              <DisplayPrices
                cutThroughPrice={
                  !isDates || isBooked
                    ? cutThroughPricePerNight
                    : cutThroughPrice
                }
                isPerNight={!isDates || isBooked ? true : false}
                price={!isDates || isBooked ? pricePerNight : finalPrice}
              />
              <button onClick={() => setIsBreakdownOpen((prev) => !prev)}>
                {isBreakdownOpen ? (
                  <ListingPageArrowUpIcon />
                ) : (
                  <ListingPageArrowDownIcon />
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
